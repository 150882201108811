export function getAtSession(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export function setInSession(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getAtLocal(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function setInLocal(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}
