import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  USERS: `${host}admin/users/counts`,

  USERS_GROUPS: `${host}v2/admin/statistics/users_by_groups`,

  ONLINE_USERS: `${host}v2/admin/statistics/online_users_statistics`,

  COUNTRIES: `${host}v2/admin/statistics/country_statistics`,

  REGISTERED_USERS: `${host}v2/admin/statistics/users_statistics`,

  LIVE_TV: `${host}admin/channels/count`,

  PAYMENTS: `${host}payment/monthly`,

  PAYMENT_COUNT: `${host}v2/admin/statistics/payment/count`,

  PAYMENT_TOTAL: `${host}v2/admin/statistics/payment/total`,

  DEVICES: `${host}admin/devices/count`,

  DEVICE_PLATFORM: `${host}v2/admin/statistics/platform_statistics`,

  ARCHIVE: `${host}admin/archive/get?append=all`,

  MOST_WATCHED: `${host}movie/statistics/most_watched_movie`,

  MOST_WATCHED_LIST: `${host}movie/statistics/most_watched_list`,

  VOD_TOTAL: `${host}movie/statistics/totals`,

  NOW_WATCHING: `${host}movie/statistics/now_watching`,

  PER_CHANNEL: `${host}v2/admin/statistics/views_per_channel`,

  DURATION_MOVIE: `${host}v2/admin/statistics/views_duration_movie`,

  PER_MOVIE: `${host}v2/admin/statistics/views_per_movie`,

  TOP_WATCHED: `${host}v2/admin/statistics/top_watched`,

  VIEW_CHANNEL_DURATION: `${host}v2/admin/statistics/views_duration_channel`,
};

const dashboardApi = {
  USERS: (query) => request("POST", URL.USERS, query),

  USERS_GROUPS: (query) => request("GET", URL.USERS_GROUPS, query),

  ONLINE_USERS: (query) => request("GET", URL.ONLINE_USERS, query),

  COUNTRIES: (query) => request("GET", URL.COUNTRIES, query),

  REGISTERED_USERS: (query) => request("GET", URL.REGISTERED_USERS, query),

  LIVE_TV: (query) => request("POST", URL.LIVE_TV, query),

  PAYMENTS: (query) => request("POST", URL.PAYMENTS, query),

  PAYMENT_COUNT: (query) => request("GET", URL.PAYMENT_COUNT, query),

  PAYMENT_TOTAL: (query) => request("GET", URL.PAYMENT_TOTAL, query),

  DEVICES: (query) => request("POST", URL.DEVICES, query),

  DEVICE_PLATFORM: (query) => request("GET", URL.DEVICE_PLATFORM, query),

  ARCHIVE: (query) => request("POST", URL.ARCHIVE, query),

  VOD: {
    TOTALS: (query) => request("GET", URL.VOD_TOTAL, query),

    MOST_WATCHED: (query) => request("GET", URL.MOST_WATCHED, query),

    MOST_WATCHED_LIST: (query) => request("GET", URL.MOST_WATCHED_LIST, query),

    NOW_WATCHING: (query) => request("GET", URL.NOW_WATCHING, query),

    PER_MOVIE: (query) => request("GET", URL.PER_MOVIE, query),

    TOP_WATCHED: (query) => request("GET", URL.TOP_WATCHED, query),
  },

  CHARTS: {
    PER_CHANNEL: (query) => request("GET", URL.PER_CHANNEL, query),

    DURATION_MOVIE: (query) => request("GET", URL.DURATION_MOVIE, query),

    VIEW_CHANNEL_DURATION: (query) => request("GET", URL.VIEW_CHANNEL_DURATION, query),
  },
};

export default dashboardApi;
