import { useCallback, useEffect, useState } from "react";

import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import { getToken } from "../../../server/requests";

import StreamerStreamsTable from "./StreamerStreamsTable";
import StreamerStreamsDrawer from "./StreamerStreamsDrawer";

import VideoModal from "../../../_components/VideoModal";
import PageComponent from "../../../_components/page/PageComponent";
import LogDraweromponent from "../../../_components/logDrawer/LogDrawer";

export default function StreamerStreamsPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenLogsDrawer, setIsOpenLogsDrawer] = useState({
    open: false,
    data: null,
  });

  const [editable, setEditable] = useState(null);

  const [playingVideo, setPlayingVideo] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.STREAMER.STREAMS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "play":
        REQUESTS.STREAMER.STREAMS.PLAY({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: "Successfully started streaming",
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "pause":
        REQUESTS.STREAMER.STREAMS.PAUSE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: "Successfully paused streaming",
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "delete":
        REQUESTS.STREAMER.STREAMS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: "Successfully deleted stream",
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  const closeVideoModal = useCallback(() => {
    setPlayingVideo(null);
  }, []);

  useEffect(() => {
    let timeout;

    if (!playingVideo && !isOpenDrawer) {
      timeout = setTimeout(() => {
        setGetAgain((prev) => !prev);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain, playingVideo, isOpenDrawer]);

  return (
    <PageComponent routes={["Streamer", "Streams"]}>
      <StreamerStreamsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
        openPlayingVideo={(item) => setPlayingVideo(item)}
        openLogDrawer={(item) => {
          setIsOpenLogsDrawer({
            ...isOpenLogsDrawer,
            open: true,
            data: item,
          });
        }}
      />

      <StreamerStreamsDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      <LogDraweromponent
        open={isOpenLogsDrawer.open}
        onClose={() => setIsOpenLogsDrawer({ open: false, data: null })}
        channel={isOpenLogsDrawer.data}
        url={"/stream_logs"}
        title="Stream Logs"
      />

      <VideoModal
        open={playingVideo ? true : false}
        onClose={closeVideoModal}
        title={playingVideo?.name}
        // url={playingVideo?.output + "?token=" + getToken()}
        url={playingVideo?.output}
      />
    </PageComponent>
  );
}
