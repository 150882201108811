import React from "react";

export default function CheckboxComponent({ label, name, onChange, value, checked }) {
    return (
        <div className="checkbox-block">
            <label className="checkbox-label" style={{ textAlign: "start" }}>
                {label}
                <input
                    type="radio"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                />
                <span className="checkmark" />
                <span className="checkmark" />
            </label>
        </div>
    );
}
