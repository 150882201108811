import request from "../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}admin/`;

const URL = {
    LOGIN: `${host}login`,

    VALIDATE: `${host}validate`,

    PERMISSIONS: `${host}menu/permission`,

    CONTENT_PROVIDER_PERMISSION: `${host}menu/permission/content_provider`,
};

const PROFILE = {
    LOGIN: (query) => request("POST", URL.LOGIN, query),

    VALIDATE: (query) => request("POST", URL.VALIDATE, query),

    PERMISSIONS: () => request("GET", URL.PERMISSIONS),

    HAS_CONTENT_PROVIDER_PERMISSION: () => request("GET", URL.CONTENT_PROVIDER_PERMISSION),
};

export default PROFILE;
