import { useEffect, useState } from "react";
import { Popover, Tag, Tooltip } from "antd";

import icons from "../../../../../config/icons";
import { parseDate } from "../../../../../config/config";

import TableButtons from "../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";

const returnKeywordsList = (keywords) => {
    return (
        <div
            style={{
                maxWidth: "800px",
                width: "max-content",
                gap: 10,
                display: "flex",
                flexWrap: "wrap",
            }}
        >
            {keywords.map((keyword) => {
                return (
                    <>
                        <Tag>{keyword}</Tag>
                    </>
                );
            })}
        </div>
    );
};

export default function KeywordsTable({
    getAgain,
    openDrawer,
    getDataSource,

    handleMenuClick,
}) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (record, text, index) => {
                return index + 1;
            },
        },

        {
            title: "Path",
            dataIndex: "path",
            key: "path",
            align: "center",
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },

        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            align: "center",
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
        },

        {
            title: "Keywords",
            dataIndex: "keywords",
            key: "keywords",
            align: "center",
            ellipsis: true,

            render: (text, record, index) => {
                const list = record.keywords.split(",");

                return (
                    <Popover
                        content={() => returnKeywordsList(list)}
                        title="Channels"
                        trigger="hover"
                    >
                        {record.keywords}
                    </Popover>
                );
            },
        },

        {
            title: `Created date`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",

            render: (text, record, index) => {
                return parseDate(text);
            },
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this keyword`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                sort: ["id", "DESC"],
                limit: 100,
            };

            function onSuccess(data) {
                setLoading(false);
                setDataSource(data.rows);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource(query, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [getAgain]);

    return (
        <TableComponent
            header={
                <>
                    <ButtonComponent icon={icons.ADD} title="Add Keyword" onClick={openDrawer} />
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            columns={columns}
        />
    );
}
