import { useState } from "react";

import REQUESTS from "../../../api/requests";

import { Flex, Modal, notification, Radio } from "antd";

import PageComponent from "../../../_components/page/PageComponent";

import LavieTvTable from "./LavieTvTable";
import LiveTvDrawer from "./drawer/LiveTvDrawer";
import { getToken } from "../../../server/requests";
import LiveTvVideoModal from "./components/video/LiveTvVideoModal";
import ChartComponent from "../../../_components/chart/ChartComponent";
import { Area } from "@ant-design/plots";

export default function LiveTvPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [playingChannel, setPlayingChannel] = useState(null);

  const [editableChannel, setEditableChannel] = useState(null);

  const [visibleLiveTvDrawer, setVisibleLiveTvDrawer] = useState(false);

  const [channelName, setChannelName] = useState("");

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: "All time",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "year",
        value: -1,
      },
      active: false,
    },
    {
      id: 1,
      name: "Year",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "month",
        value: -1,
      },
      active: false,
    },
    {
      id: 2,
      name: "Month",
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "day",
        value: -1,
      },
      active: true,
    },
  ]);

  const [perChannelData, setPerChannelData] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  });

  const [durationChannelData, setDurationChannelData] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  });

  const [visibleVideoModal, setVisibleVideoModal] = useState(false);

  const getLiveTv = (query, onSuccess, onError) => {
    REQUESTS.LIVE_TV.CHANNELS.GET(query)
      .then((response) => {
        if (!response.message) {
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
        } else {
          onSuccess(response.message);
          console.log(response);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
        onError(error);
      });
  };

  const onEnable = (is_enabled, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("is_enabled", is_enabled);

    REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE(formData)
      .then((response) => {
        setGetAgain((prev) => !prev);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onEnableOrDisableAll = (enable) => {
    REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE_ALL({
      enable,
    })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        setGetAgain((prev) => !prev);

        notification.success({
          message: "Success",
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onChangePosition = (position, id) => {
    REQUESTS.LIVE_TV.CHANNELS.POSITION({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: "Do you want to change position",
            content: (
              <div>
                <p>
                  In {position} position already exists <b>{response?.message?.name}</b>{" "}
                  live tv. We can switch their positions. Do you want to do it?
                </p>
              </div>
            ),
            okText: "Change",
            onOk() {
              REQUESTS.LIVE_TV.CHANNELS.POSITION({ position, id, force: true })
                .then(() => {
                  notification.success({
                    message: "Success",
                    description: "Position changed successfully",
                  });

                  setGetAgain((prev) => !prev);
                })
                .catch((err) => {
                  notification.error({
                    message: "Error",
                    description: err.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });

        setGetAgain((prev) => !prev);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditableChannel(item);
        setVisibleLiveTvDrawer(true);
        break;

      case "archive":
        REQUESTS.LIVE_TV.CHANNELS.ARCHIVE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "restore":
        REQUESTS.LIVE_TV.CHANNELS.RESTORE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "delete":
        REQUESTS.LIVE_TV.CHANNELS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
      case "statistics":
        getChannelStatistics(item.id);
        setChannelName(item.name);
        break;
      default:
        break;
    }
  };

  const getChannelStatistics = (id) => {
    const params =
      selectedData.filter((item) => item.id === id)[0]?.query || selectedData[2].query;

    const requestsArr = [
      REQUESTS.DASHBOARD.CHARTS.PER_CHANNEL({ query: JSON.stringify(params) }),
      REQUESTS.DASHBOARD.CHARTS.VIEW_CHANNEL_DURATION({ query: JSON.stringify(params) }),
    ];

    const promises = Promise.all(requestsArr);
    promises
      .then((response) => {
        if (!response.error) {
          setVisibleVideoModal(true);

          setPerChannelData({
            ...perChannelData,
            data: response[0]?.message?.data,
          });

          setDurationChannelData({
            ...durationChannelData,
            data: response[1]?.message?.data,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  const bulkActions = (type, ids) => {
    switch (type) {
      case "enable":
        REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE_BULK({
          id: ids.join(","),
          is_enabled: true,
          token: getToken(),
        })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "disable":
        REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE_BULK({
          id: ids.join(","),
          is_enabled: false,
          token: getToken(),
        })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "archive":
        REQUESTS.LIVE_TV.CHANNELS.ARCHIVE({ id: ids })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "restore":
        REQUESTS.LIVE_TV.CHANNELS.RESTORE({ id: ids })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      // case "edit":
      //   REQUESTS.LIVE_TV.CHANNELS.BULK_UPDATE(body)
      //   .then((response) => {
      //     if (!response.error) {
      //       notification.success({
      //         message: "Success",
      //         description: response.message,
      //       });
      //       setGetAgain((prev) => !prev);

      //     } else {
      //       notification.error({
      //         message: "Error",
      //         description: response?.message,
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      // break;

      case "delete":
        REQUESTS.LIVE_TV.CHANNELS.DELETE({ id: ids })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  const handleSelectTab = (value) => {
    const selectActive = selectedData.filter((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          active: true,
          value: value.id,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });
    setSelectedData(selectActive);

    getChannelStatistics(value.id);
  };

  return (
    <PageComponent routes={["Live TV", "Live TV"]}>
      <LavieTvTable
        getAgain={getAgain}
        onEnable={onEnable}
        getDataSource={getLiveTv}
        handleMenuClick={handleMenuClick}
        onChangePosition={onChangePosition}
        openDrawer={() => setVisibleLiveTvDrawer(true)}
        onPlay={(channel) => setPlayingChannel(channel)}
        bulkActions={bulkActions}
        onEnableOrDisableAll={onEnableOrDisableAll}
      />

      <LiveTvDrawer
        open={visibleLiveTvDrawer}
        editable={editableChannel}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setEditableChannel(null);
          setVisibleLiveTvDrawer(false);
        }}
      />

      <LiveTvVideoModal
        channel={playingChannel}
        open={playingChannel ? true : false}
        onClose={() => setPlayingChannel(null)}
      />
      <Modal
        title={channelName}
        open={visibleVideoModal}
        onCancel={() => setVisibleVideoModal(false)}
        footer={null}
        width={"70%"}
        onOk={() => {
          setVisibleVideoModal(false);
        }}
      >
        <Radio.Group
          defaultValue={selectedData[0]}
          style={{
            marginTop: 25,
          }}
          buttonStyle="solid"
        >
          {selectedData?.map((item) => (
            <Radio.Button
              key={item?.id}
              value={item}
              onClick={() => handleSelectTab(item)}
            >
              {item?.name}
            </Radio.Button>
          ))}
        </Radio.Group>

        <Flex gap={"10px"} justify="center">
          <div style={{ margin: "20px auto", width: "50%" }}>
            <ChartComponent
              title={"Duration Chart"}
              children={<Area {...durationChannelData} />}
            />
          </div>
          <div style={{ margin: "20px auto", width: "50%" }}>
            <ChartComponent
              title={"Per Channel Chart"}
              children={<Area {...perChannelData} />}
            />
          </div>
        </Flex>
      </Modal>
    </PageComponent>
  );
}
