import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, notification, Tabs, Row, Space } from "antd";
import InformationStep from "./InformationStep";
import SetupStreamsStep from "./SetupStreamsStep";
import RestrictionStep from "./RestrictionStep";
import CatchUpStep from "./CatchUpStep";
import REQUESTS from "../../../../api/requests";
import {
  useDevicesOptions,
  useLiveTvCategoriesOptions,
} from "../../../../hooks/selectOptions";
import redirectToStepOfTheErrorField from "./redirectToStepOfTheErrorField";
import createLiveTvFormData from "./createLiveTvFormData";
import onSetEditableValues from "./onSetEditableValues";
import { AGE_RESTRICTION } from "../../../../config/statics";
import { streamTypeOptions } from "../../../../config/config";
import ButtonComponent from "../../../../components/ButtonComponent";

export default function LiveTvDrawer({ open, onClose, editable, getData }) {
  const { permissions } = useSelector((state) => state.globalState);

  const devicesOptions = useDevicesOptions();

  const categoriesOption = useLiveTvCategoriesOptions();

  const allDevicesIds = devicesOptions.map((item) => item.value);
  allDevicesIds.shift();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const [step, setStep] = useState("1");

  const [poster, setPoster] = useState({
    url: null,
    file: null,
  });

  const [backdrop, setBackdrop] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setLoading(true);
    const formData = createLiveTvFormData(
      values,
      poster,
      backdrop,
      editable,
      permissions
    );

    if (editable) {
      formData.append("id", editable.id);

      REQUESTS.LIVE_TV.CHANNELS.EDIT(formData)
        .then((response) => {
          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });
            setLoading(false);

            return;
          }

          setLoading(false);

          onClose();
          getData();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err,
          });

          setLoading(false);
        });
    } else {
      REQUESTS.LIVE_TV.CHANNELS.ADD(formData)
        .then((response) => {
          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });
            setLoading(false);

            return;
          }
          setLoading(false);
          onClose();
          getData();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err,
          });
          setLoading(false);
        });
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    redirectToStepOfTheErrorField(errorFields, setStep);
  };

  const items = [
    {
      key: "1",
      label: ` Information`,
      children: (
        <InformationStep
          editable={editable}
          form={form}
          setStep={setStep}
          poster={poster}
          setPoster={setPoster}
          backdrop={backdrop}
          setBackdrop={setBackdrop}
          categoriesOption={categoriesOption}
        />
      ),
      forceRender: true,
    },
    {
      key: "2",
      label: `Setup Streams`,
      children: <SetupStreamsStep setStep={setStep} form={form} />,
      forceRender: true,
    },
    {
      key: "3",
      label: `Restrictions`,
      children: (
        <RestrictionStep
          form={form}
          setStep={setStep}
          devicesOptions={devicesOptions}
          allDevicesIds={allDevicesIds}
          categoriesOption={categoriesOption}
        />
      ),
      forceRender: true,
    },

    {
      key: "4",
      label: `Catch-up`,
      children: (
        <CatchUpStep
          setStep={setStep}
          form={form}
          loading={loading}
          editable={editable}
        />
      ),
      forceRender: true,
    },
  ];

  useEffect(() => {
    form.setFields([{ name: "poster", value: poster.file }]);
  }, [poster]);

  useEffect(() => {
    form.setFields([{ name: "backdrop", value: backdrop.file }]);
  }, [backdrop]);

  useEffect(() => {
    if (open) {
      form.resetFields();
      setLoading(false);
    } else {
      setStep("1");

      setPoster({
        url: null,
        file: null,
      });
      setBackdrop({
        url: null,
        file: null,
      });
    }

    if (editable) {
      onSetEditableValues(form, editable, setPoster, setBackdrop, permissions);
    }
  }, [open, editable]);

  useEffect(() => {
    if (step === "3") {
      const { categories } = form.getFieldsValue();

      if (categories) {
        const selectedCategories = categoriesOption.filter((obj) =>
          categories.includes(obj.id)
        );

        for (let i = 0; i < selectedCategories.length; i++) {
          if (selectedCategories[i].is_protected) {
            form.setFields([{ name: "is_protected", value: true }]);
            break;
          }
        }
      }
    }
  }, [step]);

  return (
    <Drawer
      title="Live Tv"
      placement="right"
      onClose={onClose}
      open={open}
      width={500}
      extra={
        <ButtonComponent
          type="save_settings"
          htmlType="submit"
          style={{ color: "#000" }}
          action={form.submit}
        />
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        initialValues={{
          is_protected: false,
          has_archive: false,
          is_location_restriction: false,
          age_restricted: AGE_RESTRICTION,
          content_monetization_type: "stv",
          epg_type: "XMLTV",
          permission_devices: allDevicesIds,
          archived_channel_type: "inorain",
          stream_type: streamTypeOptions[0].value,
        }}
      >
        <Tabs defaultActiveKey="1" items={items} activeKey={step} onChange={setStep} />
      </Form>
    </Drawer>
  );
}
