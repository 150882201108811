import React from "react";

export default function InputComponent({
  label,
  name,
  type,
  onChange,
  value,
  placeholder,
  autoComplete,
  readOnly,
  disabled,
  style,
  className,
  min,
  max,
}) {
  return (
    <div className="form-group" style={{ textAlign: "start", ...style }}>
      {label && <label className="f-w-500 m-b-5">{label}:</label>}

      <input
        min={min}
        max={max}
        name={name}
        value={value}
        type={type || "text"}
        className={`input ${className}`}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        readOnly={readOnly}
        disabled={disabled}
      />
    </div>
  );
}
