import { Typography, Switch } from "antd";

import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";
import { parseDate } from "../../../../../../config/config";

import TableButtons from "../../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

export default function PlaylistsTable({
    user,
    getAgain,
    onDefault,
    openDrawer,
    getDataSource,
    handleMenuClick,
}) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            render: (record, text, index) => {
                return index + 1;
            },
        },

        {
            title: "Default",
            dataIndex: "is_default",
            align: "center",
            key: "is_default",
            width: 100,
            render: (text, record, index) => {
                return (
                    <Switch
                        size="small"
                        checked={record.is_default}
                        onChange={(e) => onDefault(e, record)}
                    />
                );
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },

        {
            title: "User",
            dataIndex: "sub_user",
            key: "sub_user",
            align: "center",

            render: (text, record, index) => {
                return record.sub_user == null
                    ? user?.name + " " + user?.surname
                    : record?.sub_user?.name;
            },
        },

        {
            title: "Url",
            dataIndex: "url",
            key: "url",
            align: "center",
            width: 200,
            render: (text, record, index) => {
                return (
                    <Typography.Paragraph
                        ellipsis={true}
                        style={{ margin: 0, width: 200 }}
                        copyable={{
                            tooltips: false,
                        }}
                    >
                        {record.url}
                    </Typography.Paragraph>
                );
            },
        },
        {
            title: `Created date`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",

            render: (text, record, index) => {
                return parseDate(record.createdAt);
            },
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this playlist`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            function onSuccess(data) {
                setLoading(false);
                setDataSource(data);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource({ id: user.id }, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [getAgain]);

    return (
        <TableComponent
            header={
                <>
                    <ButtonComponent icon={icons.ADD} title="Add Playlist" onClick={openDrawer} />
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            columns={columns}
            user={user}
        />
    );
}
