import React, { useEffect, useState } from "react";
import { Image, Popconfirm, Space, Tag, Tooltip } from "antd";

import icons from "../../../config/icons";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import PositionComponent from "../../../_components/PositionComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import defaultImage from "../../../img/photo.png";
import { parseDateFormat } from "../../../config/config";

import {
  getAtLocal,
  getAtSession,
  setInSession,
  setInLocal,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import { useNavigate } from "react-router";
import PATHS from "../../../config/paths";

export default function VodCollectionTable({
  getAgain,
  getDataSource,
  handleMenuClick,
  onChangePosition,
  bulkActions,
  openDrawer,
  onVisible,
}) {
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(getAtLocal("pageLimit_collections") || 10);
  const [currentPage, setCurrentPage] = useState(getAtSession("collections")?.page || 1);
  const [sort, setSort] = useState(getAtSession("collections")?.sort || ["id", "DESC"]);
  const [total, setTotal] = useState(0);
  // const [search, setSearch] = useState(
  //   getAtSession("collections")?.search || {
  //     name: null,
  //     categories: null,
  //   }
  // );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      width: 40,
      title: "#",
      key: "index",
      defaultSortOrder:
        getAtSession("collections")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("collections")?.sort?.[1]),
      dataIndex: `id`,
      align: "center",
      fixed: "left",

      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      width: 90,
      title: "Position",
      defaultSortOrder:
        getAtSession("collections")?.sort?.[0] === "position" &&
        asc_desc(getAtSession("collections")?.sort?.[1]),
      dataIndex: `position`,
      align: "center",
      key: "position",
      sorter: true,
      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <PositionComponent
            position={record.position}
            onChangePosition={(e) => onChangePosition(e, record?.id)}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      width: 110,
      render: (text, reacord, index) => {
        return (
          <Tooltip placement="topLeft" title={reacord.name}>
            <p style={{ margin: 0, marginLeft: 10 }}>{reacord.name}</p>
          </Tooltip>
        );
      },
    },
    {
      title: "Icon",
      dataIndex: "icon",
      align: "center",
      width: 70,
      render: (text, reacord, index) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Image
              src={reacord.icon}
              width={30}
              height={30}
              style={{ objectFit: "cover", borderRadius: "50%" }}
              fallback={defaultImage}
            />
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "is_protected",
      key: "is_protected",
      align: "center",

      width: 90,
      render: (text, record, index) => {
        return record.is_protected ? (
          <Tag color="green">Protected</Tag>
        ) : (
          <Tag color="volcano">No Protected</Tag>
        );
      },
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 90,

      render: (text, record, index) => {
        return parseDateFormat(text);
      },
    },
    {
      title: `Visible`,
      dataIndex: "visible",
      key: "visible",
      align: "center",

      width: 60,
      render: (text, record, index) => {
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {record.visible ? (
              <ButtonComponent
                type="text"
                onClick={() => onVisible(record.id, false)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-eye"></i>',
                    }}
                  />
                }
              />
            ) : (
              <ButtonComponent
                type="text"
                danger={true}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => onVisible(record.id, true)}
                icon={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-eye-slash"></i>',
                    }}
                  />
                }
              />
            )}
          </div>
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <TableButtons
            disabled={record.default}
            handleMenuClick={(e) => {
              handleMenuClick(e, record);
            }}
            items={[
              {
                key: "edit",
                name: "Edit",
                icon: icons.EDIT,
              },

              record.is_protected
                ? {
                    key: "unprotect",
                    name: "Unprotect",
                    icon: icons.UNPROTECT,
                    question: `Do you want to unprotect this collection?`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  }
                : {
                    key: "protect",
                    name: "Protect",
                    icon: icons.PROTECT,
                    question: `Do you want to protect this collection?`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  },

              {
                key: "delete",
                name: "Delete",
                icon: icons.DELETE,
                question: `Do you want to delete this collection?`,
                onConfirm: (e) => {
                  handleMenuClick(e, record);
                },
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleTableChange = (data) => {
    const collections = {
      page: data.page,
      // search: data.search,
    };
    if (data.sort) {
      collections.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_collections", data.limit);
    setInSession("collections", collections);

    setCurrentPage(data?.page);
    setLimit(data?.limit);
    setSort(data?.sort);
    // setSearch({
    //   ...search,
    //   name: data.search,
    // });
  };

  useEffect(() => {
    const fetchCollections = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      // if (search.name) {
      //   query.search = {
      //     name: search.name,
      //   };
      // }

      // if (search.categories) {
      //   query.filter = {
      //     categoryId: search.categories,
      //   };
      // }

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data?.message?.rows);
        setTotal(data?.message?.total);

        const maxPage = Math.ceil(data?.message?.count / limit);
        const storedPage = getAtSession("collections")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("collections", {
            ...getAtSession("collections"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("collections", {
            ...getAtSession("collections"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
        console.log(data);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("collections") &&
        getAtSession("collections")?.page > currentPage
      ) {
        setInSession("collections", {
          ...getAtSession("collections"),
          page: 1,
        });
        setCurrentPage(getAtSession("collections")?.page);
      }

      fetchCollections();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, currentPage, getAgain]);

  const bulDeleteAction = (item) => {
    const selectedItem = item?.map((id) => id);

    return selectedItem?.join(",");
  };

  return (
    <div>
      <TableComponent
        header={
          <div style={{ display: "flex", gap: 15 }}>
            {selectedRowKeys.length > 0 ? (
              <Space>
                <Popconfirm
                  placement="topRight"
                  title="Do you want to delete selected collections?"
                  onConfirm={(e) =>
                    handleMenuClick(
                      { key: "delete" },
                      { id: bulDeleteAction(selectedRowKeys) }
                    )
                  }
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <ButtonComponent type="default" title="Delete" />
                </Popconfirm>
              </Space>
            ) : null}
            <Space>
              <ButtonComponent
                title={"Add Collection"}
                icon={icons.ADD}
                onClick={() => openDrawer()}
              />
            </Space>
          </div>
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(PATHS.COLLECTION_PAGE + record.id);
            },
          };
        }}
        // rowClassName="clickable-row"

        rowSelection={rowSelection}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        onChange={handleTableChange}
        rowClassName={(row) => {
          // if (row.archive) return "red-row";
          return row?.archive ? "red-row" : "clickable-row";
        }}
        // rowClassName="clickable-row"
        pagination={{
          page: currentPage,
          limit: limit,
          total: total,
        }}
        scroll={{
          x: 1400,
        }}
      />
    </div>
  );
}
