import { Image, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";

import icons from "../../../config/icons";
import IMAGES from "../../../config/images";
import { getColumnSearchProps, parseDateFormat } from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

import Check from "../../../_components/table/Check";
import TableEmpty from "../../../_components/table/TableEmpty";

export default function CastTable({ getAgain, openDrawer, getDataSource, handleMenuClick }) {
    const [dataSource, setDataSource] = useState([]);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [total, setTotal] = useState(0);

    const [search, setSearch] = useState({
        name: null,
    });

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            fixed: "left",
            render: (record, text, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 200,
            render: (text, record, index) => {
                return (
                    <div style={{ textAlign: "start" }}>
                        <Space>
                            <Image
                                src={record.image}
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                }}
                                fallback={IMAGES.USER}
                            />
                            <span>{record.name}</span>
                        </Space>
                    </div>
                );
            },

            ...getColumnSearchProps(),
        },

        {
            title: "Gender",
            dataIndex: "gender",
            key: "gender",
            align: "center",
            render: (text, record, index) => {
                return record.gender || <TableEmpty />;
            },
        },

        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            align: "center",
            width: 200,
            render: (text, record, index) => {
                return (
                    <Tooltip placement="topLeft" title={record.country}>
                        {record.country || <TableEmpty />}
                    </Tooltip>
                );
            },
        },

        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            align: "center",
            render: (text, record, index) => {
                return record.role || <TableEmpty />;
            },
        },

        {
            title: `Birthday`,
            dataIndex: "birthday",
            key: "birthday",
            align: "center",

            render: (text, record, index) => {
                return record.birthday ? parseDateFormat(record.birthday) : <Check value={false} />;
            },
        },

        {
            title: `Deathday`,
            dataIndex: "deathday",
            key: "deathday",
            align: "center",

            render: (text, record, index) => {
                return record.deathday ? parseDateFormat(record.deathday) : <Check value={false} />;
            },
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "info",
                            name: "Info",
                            icon: icons.INFO,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this cast?`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const handleTableChange = (data) => {
        setCurrentPage(data.page);
        setLimit(data.limit);
        setSort(data.sort);

        setSearch(data.search);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                sort,
                limit,
                page: currentPage,
            };

            if (search.name) {
                query.search = {
                    name: search.name[0],
                };
            }

            function onSuccess(data) {
                if (data?.rows.length === 0 && currentPage > 1) {
                    setCurrentPage((prev) => prev - 1);
                    return;
                }

                setLoading(false);
                setDataSource(data.rows);

                setTotal(data?.total);
                setLimit(data?.limit);
                setCurrentPage(data?.page);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource(query, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [limit, currentPage, sort, search, getAgain]);

    return (
        <TableComponent
            header={
                <>
                    <ButtonComponent icon={icons.ADD} title="Add Cast" onClick={openDrawer} />
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            onChange={handleTableChange}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
            scroll={{ x: 1000 }}
        />
    );
}
