import { useEffect, useState } from "react";
import { Image } from "antd";
import { getAtSession, setInSession, setInLocal } from "../../helpers/storages";

import icons from "../../config/icons";
import {
  getColumnSearchProps,
  getColumnDateProps,
  getQueryDate,
  parseDate,
} from "../../config/config";

import TableButtons from "../../_components/table/TableButtons";
import TableComponent from "../../_components/table/TableComponent";
import ButtonComponent from "../../_components/button/ButtonComponent";

import userImage from "../../img/user.png";

export default function AdminsTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(getAtSession("admins")?.page || 1);

  const [sort, setSort] = useState(getAtSession("admins")?.sort || ["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("admins")?.search || {
      name: null,
      surname: null,
      phone: null,
      email: null,
    }
  );

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record, index) => {
        return (
          <Image
            src={record.image}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
            fallback={userImage}
          />
        );
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      align: "center",
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this admin`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setInLocal("pageLimit", data.limit);
    setInSession("admins", {
      ...getAtSession("admins"),
      page: data.page,
      limit: data.limit,
      sort: data.sort,
    });

    setSearch(data.search);
  };

  // useEffect(() => {
  //     const timeout = setTimeout(() => {
  //         setLoading(true);

  //         const query = {
  //             sort,
  //             limit,
  //             page: currentPage,
  //         };

  //         if (search.name) {
  //             query.search = {
  //                 name: search.name[0],
  //             };
  //         }

  //         const queryDate = getQueryDate(date);

  //         if (queryDate) query.between = queryDate;

  //         function onSuccess(data) {
  //             if (data?.rows.length === 0 && currentPage > 1) {
  //                 setCurrentPage((prev) => prev - 1);
  //                 return;
  //             }

  //             setLoading(false);
  //             setDataSource(data.rows);

  //             setTotal(data?.total);
  //             setLimit(data?.limit);
  //             setCurrentPage(data?.page);
  //         }

  //         function onError(data) {
  //             setLoading(false);
  //         }

  //         getDataSource(query, onSuccess, onError);
  //     }, 500);

  //     return () => {
  //         clearTimeout(timeout);
  //     };
  // }, [limit, currentPage, sort, search, date, getAgain]);

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data?.rows);
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("admins")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("admins", {
            ...getAtSession("admins"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("admins", {
            ...getAtSession("admins"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }
      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (getAtSession("admins") && getAtSession("admins")?.page > currentPage) {
        setInSession("admins", {
          ...getAtSession("admins"),
          page: 1,
        });
        setCurrentPage(getAtSession("admins")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add Admin" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
