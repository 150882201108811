import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  LAUNCHER: `${host}admin/launcher/app_basics`,
};

const launcherAppsApi = {
  GET: (query) => request("GET", URL.LAUNCHER, query),

  EDIT: (query) => request("PUT", URL.LAUNCHER, query),

  POSITION: (query) => request("PUT", `${URL.LAUNCHER}/position`, query),
};

export default launcherAppsApi;
