import { Tooltip } from "antd";
import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import {
    getColumnDateProps,
    getColumnSearchProps,
    getQueryDate,
    parseDate,
} from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function LessonsTable({ getAgain, openDrawer, getDataSource, handleMenuClick }) {
    const [dataSource, setDataSource] = useState([]);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [date, setDate] = useState(null);

    const [total, setTotal] = useState(0);

    const [search, setSearch] = useState({
        name: null,
    });

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            fixed: "left",
            key: "index",
            render: (record, text, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 250,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Tooltip placement="topLeft" title={text}>
                        {text}
                    </Tooltip>
                );
            },

            ...getColumnSearchProps(),
        },

        {
            title: "Level",
            dataIndex: "level",
            key: "level",
            align: "center",
            sorter: true,
        },

        {
            title: "Unit",
            dataIndex: "unit",
            key: "unit",
            align: "center",
            sorter: true,
        },

        {
            title: `Created date`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: 180,

            render: (text, record, index) => {
                return parseDate(text);
            },

            ...getColumnDateProps(setDate),
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this lesson`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const handleTableChange = (data) => {
        setCurrentPage(data.page);
        setLimit(data.limit);
        setSort(data.sort);

        setSearch(data.search);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                sort,
                limit,
                page: currentPage,
            };

            if (search.name) {
                query.search = {
                    name: search.name[0],
                };
            }

            const queryDate = getQueryDate(date);

            if (queryDate) query.between = queryDate;

            function onSuccess(data) {
                if (data?.rows.length === 0 && currentPage > 1) {
                    setCurrentPage((prev) => prev - 1);
                    return;
                }

                setLoading(false);
                setDataSource(data.rows);

                setTotal(data?.total);
                setLimit(data?.limit);
                setCurrentPage(data?.page);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource(query, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [limit, currentPage, sort, search, date, getAgain]);

    return (
        <TableComponent
            header={
                <>
                    <ButtonComponent icon={icons.ADD} title="Add Lesson" onClick={openDrawer} />
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            onChange={handleTableChange}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
        />
    );
}
