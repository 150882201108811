import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "react-datepicker/dist/react-datepicker.css";

import play from "../../img/play.svg";
import pause from "../../img/pause.svg";

import Popup from "../../popups/Popup";
import SmtpDrawer from "./SmtpDrawer";
import { formatDateEN } from "../../helpers/formats";
import QuestionPopup from "../../popups/QuestionPopup";
import PageComponent from "../../components/PageComponent";
import SelectComponent from "../../components/SelectComponent";

import ButtonComponent from "../../components/ButtonComponent";
import DatePickerComponent from "../../components/DatePickerComponent";
import InputComponent from "../../components/InputComponent";
import TextareaComponent from "../../components/TextareaComponent";
import CheckboxComponent from "../../components/CheckboxComponent";
import TableComponent from "../../components/TableComponent";
import TableEmptyComponent from "../../components/TableEmptyComponent";
import TableLoadingComponent from "../../components/TableLoadingComponent";

import requests, {
  rGetRecords,
  rPlayPauseEmail,
  rSmtpSetChecked,
  rCheckIfSmtpEnabled,
  rSearchUsers,
  rSendMeEmailNotification,
  rDeleteEmail,
  getSmtpNotifications,
} from "../../server/requests";
import Colors from "../../helpers/Colors";
import REQUESTS from "../../api/requests";

class EmailNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendTime: "1",
      smtpState: false,
      usersList: [],
      selectedOption: [],
      status: { value: "", label: "All" },
      selectedTariff: { value: "", label: "All" },
      tariffsList: [],
      startDate: new Date(),
      endDate: new Date(),
      subject: "",
      sender_name: "",
      message: "",
      sendTimeDate: "",
      sendSchedulingMonth: new Date(),
      selectedUsers: 0,
      rec_now: [],
      rec_intime: [],
      rec_schedule: [],

      popup: {
        show: false,
        type: "",
        size: "",
        title: "",
        popupBody: "",
        action: "",
      },

      showLoading: false,

      showSmtpPopup: false,
    };

    this.smtpRef = React.createRef();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleChangeStartDate = (date) => this.setState({ startDate: date });

  handleChangeEndDate = (date) => this.setState({ endDate: date });

  handleChangeInTime = (date) => this.setState({ sendTimeDate: date });

  handleChangeInMonth = (date) => this.setState({ sendSchedulingMonth: date });

  handleSelectSendDate = (event) => this.setState({ sendTime: event.target.value });

  handleSelectStatus = (selectedStatus) => this.setState({ status: selectedStatus });

  handleSelectTariff = (selectedTariff) =>
    this.setState({ selectedTariff: selectedTariff });

  handleMultiselectInput = (selectedOption) => this.setState({ selectedOption });

  smtpSetChecked = (event) => {
    const status = event.target.checked ? 1 : 0;

    rSmtpSetChecked(
      status,
      () => this.setState({ smtpState: status }),
      () => this.setState({ smtpState: false })
    );
  };

  handleMultiselect = (selectedOption) => {
    let options = [];

    rSearchUsers({ query: selectedOption }, (data) => {
      for (let i = 0; i < data.length; i++) {
        let obj = {};
        obj.value = data[i].id;
        obj.label = data[i].name + " " + data[i].surname;
        obj.email = data[i].email;
        options.push(obj);
      }
      this.setState({ usersList: options });
    });
  };

  sendEmailNotification = async () => {
    let headers = { "Content-Type": "application/x-www-form-urlencoded" };
    let body = { token: localStorage.getItem("admtoken") };
    let users;

    this.setState({ showLoading: true });

    // 0-users
    // 1-status
    // 2-tariff
    // 3-date

    let reqData = [];

    switch (this.state.selectedUsers) {
      case 0:
        users = this.state.selectedOption;
        this.sendEmail(users);
        break;
      case 1:
        body.type = this.state.status.value;
        rSearchUsers(body, (data) => {
          this.sendEmail(data);
        });
        break;
      case 2:
        body.tariffId = this.state.selectedTariff.value;
        rSearchUsers(body, (data) => {
          this.sendEmail(data);
        });
        break;
      case 3:
        body.date_start = this.state.startDate;
        body.date_end = this.state.endDate;
        rSearchUsers(body, (data) => {
          this.sendEmail(data);
        });
        break;
    }
  };

  sendEmail(reqData) {
    let users = reqData;
    console.log(users);
    let recipients = [];
    if (users) {
      for (let i = 0; i < users.length; i++) {
        recipients.push({
          email: users[i].email,
          userId: users[i].value || users[i].id,
        });
      }
    }

    let body = {
      token: localStorage.getItem("admtoken"),
      name: this.state.subject,
      sender_name: this.state.sender_name,
      message: this.state.message,
      recipients: JSON.stringify(recipients),
      sender: localStorage.getItem("username"),
      tz: new Date().toTimeString().split("GMT")[1].split(0, 3).join(""),
    };

    switch (this.state.sendTime) {
      case "1":
        body.type = "now";
        break;
      case "2":
        body.type = "in_time";
        body.send_after = this.state.sendTimeDate;
        break;
      case "3":
        let schedMonth = this.state.sendSchedulingMonth;

        body.schedule_type = "month";
        body.type = "schedule";
        body.schedule_day = schedMonth.getDate();
        body.schedule_time = schedMonth.getHours() + ":" + schedMonth.getMinutes();
        break;
    }

    const stopLoading = () => this.setState({ showLoading: false });

    rSendMeEmailNotification(body, stopLoading, stopLoading);
  }

  getTariffs = () => {
    REQUESTS.PAYMENTS.SUBSCRIPTIONS.GET().then((response) => {
      if (!response.error) {
        const data = response.message?.rows?.map((item) => {
          return {
            label: item.name,
            value: item.id,

            ...item,
          };
        });

        let firstObj = {
          value: "",
          label: "All",
        };

        data.unshift(firstObj);

        this.setState({ tariffsList: data });
      }
    });

    // requests.tariff.get((data) => {
    //   for (let i = 0; i < data.length; i++) {
    //     let obj = {};
    //     obj.value = data[i].id;
    //     obj.label = data[i].name;
    //     tariffs.push(obj);
    //   }

    // });
  };

  getRecords = () => {
    this.setState({ showLoading: true });

    rGetRecords((data) => {
      if (this.state.popup.show) this.closePopup();

      this.setState({
        rec_now: data.now,
        rec_intime: data.in_time,
        rec_schedule: data.scheduled,
      });

      clearTimeout(this.loadingTimeout);

      this.loadingTimeout = setTimeout(() => {
        this.setState({ showLoading: false });
      }, 500);
    });
  };

  playPauseEmail(status, id, key) {
    rPlayPauseEmail(id, status, () => {
      let schedRec = this.state.rec_schedule;
      schedRec[key].status = status;
      this.setState({ rec_schedule: schedRec });
    });
  }

  loadRecordsInTime = (index) => {
    if (index === 1) this.getRecords();
  };

  openPopup = (type, item) => {
    switch (type) {
      case "smtp":
        this.setState({
          showSmtpPopup: true,
        });
        break;

      case "deleteMonthShedulingEmail":
        this.setState({
          popup: {
            show: true,
            type: "Delete",
            title: "Delete email",
            popupBody: (
              <QuestionPopup
                title="Are you sure to delete this emial?"
                subtitle={`You can't restore this email again.`}
              />
            ),
            action: () => rDeleteEmail(item.id, this.getRecords),
          },
        });
        break;

      case "deleteInTimeEmail":
        this.setState({
          popup: {
            show: true,
            type: "Delete",
            title: "Delete email",
            popupBody: (
              <QuestionPopup
                title="Are you sure to delete this emial?"
                subtitle={`You can't restore this email again.`}
              />
            ),
            action: () => rDeleteEmail(item.id, this.getRecords),
          },
        });
        break;

      default:
        break;
    }
  };

  closePopup = () => {
    this.setState({
      popup: {
        show: false,
        type: "",
        size: "",
        title: "",
        popupBody: "",
        action: "",
      },
    });
  };

  componentDidMount = () => {
    getSmtpNotifications((data) => {
      this.setState({ smtpState: data.enabled });
      this.setState({ sender_name: data.name });
    });
    // rCheckIfSmtpEnabled(
    //     (data) => {
    //         console.log(data)
    // this.setState({ smtpState: true })
    //     } ),
    //     () => this.setState({ smtpState: false })
    // );

    this.getTariffs();
    // this.socket = io("http://git.inoclouds.com:8086");
    // this.socket.on("connect", ()=> console.log('Socket connected'));

    // this.socket.emit("/admin/devices_count",{key:"AxRM098062"})
    // this.socket.on("count",(data)=>console.log(data));

    // this.socket.emit("/admin/online_devices",{key:"AxRM098062"});
    // this.socket.on("devices",(data) => console.log(data));
  };

  render() {
    let statusObj = [
      { value: "", label: "All" },
      { value: "active", label: "Active" },
      { value: "blocked", label: "Blocked" },
      { value: "deleted", label: "Deleted" },
    ];

    console.log(this.state.tariffsList);

    const { showLoading } = this.state;

    return (
      <PageComponent fluid header="Email notifications">
        <div className="col-md-12 smtp-button-section smtp-on-of-parent">
          <ButtonComponent
            type="smtp"
            title="SMTP Server settings"
            disabled={!this.state.smtpState}
            action={() => this.openPopup("smtp")}
          />

          <input
            type="checkbox"
            className="ios8-switch"
            id={"checkbox-1"}
            onChange={(event) => this.smtpSetChecked(event)}
            checked={this.state.smtpState}
          />

          <label htmlFor={"checkbox-1"} />
        </div>

        {/* {this.state.smtpState && (
         */}
        {this.state.smtpState ? (
          <div className="col-md-12 overflow-auto">
            <Tabs onSelect={(index) => this.loadRecordsInTime(index)}>
              <TabList>
                <Tab>
                  <i className="fas fa-pencil-alt" />
                  Write email
                </Tab>
                <Tab>
                  <i className="fas fa-history" />
                  History
                </Tab>
              </TabList>

              <TabPanel>
                <div className="form-group">
                  <Tabs onSelect={(index) => this.setState({ selectedUsers: index })}>
                    <TabList>
                      <Tab>By client</Tab>
                      <Tab>By status</Tab>
                      <Tab>By tariff</Tab>
                      <Tab>By date</Tab>
                    </TabList>

                    <TabPanel>
                      <SelectComponent
                        label="Send email by client"
                        isMulti={true}
                        value={this.state.selectedOption}
                        options={this.state.usersList}
                        change={this.handleMultiselectInput}
                        onInputChange={this.handleMultiselect}
                      />
                    </TabPanel>

                    <TabPanel>
                      <SelectComponent
                        label="Send email by status"
                        value={this.state.status}
                        options={statusObj}
                        change={this.handleSelectStatus}
                      />
                    </TabPanel>

                    <TabPanel>
                      <SelectComponent
                        label="Send email by tariff"
                        options={this.state.tariffsList}
                        change={this.handleSelectTariff}
                        value={this.state.selectedTariff}
                      />
                    </TabPanel>

                    <TabPanel>
                      <label className="f-w-500 m-b-5">Send email by date:</label>

                      <div className="d-flex">
                        <DatePickerComponent
                          label="Start date"
                          selected={this.state.startDate}
                          onChange={this.handleChangeStartDate}
                        />

                        <div style={{ marginLeft: 5 }}>
                          <DatePickerComponent
                            label="End date"
                            selected={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
                <InputComponent
                  label="Sender name"
                  name="sender_name"
                  value={this.state.sender_name}
                  onChange={this.onChange}
                />
                <InputComponent
                  label="Subject"
                  name="subject"
                  value={this.state.subject}
                  onChange={this.onChange}
                />

                <TextareaComponent
                  label="Message"
                  name="message"
                  value={this.state.message}
                  onChange={this.onChange}
                />

                <div className="email-notification-footer">
                  <div className="email-box-block">
                    <CheckboxComponent
                      value="1"
                      label="Send now"
                      name="sendType"
                      onChange={this.handleSelectSendDate}
                      checked={this.state.sendTime === "1"}
                    />
                  </div>

                  <div className="email-box-block">
                    <CheckboxComponent
                      value="2"
                      label="In time"
                      name="sendType"
                      checked={this.state.sendTime === "2"}
                      onChange={this.handleSelectSendDate}
                    />

                    {this.state.sendTime === "2" && (
                      <DatePickerComponent
                        showTimeSelect
                        autoFocus
                        label="Send email"
                        minDate={new Date()}
                        selected={this.state.sendTimeDate}
                        onChange={this.handleChangeInTime}
                      />
                    )}
                  </div>

                  <div className="email-box-block">
                    <CheckboxComponent
                      value="3"
                      label="Month scheduling"
                      name="sendType"
                      onChange={this.handleSelectSendDate}
                      checked={this.state.sendTime === "3"}
                    />

                    {this.state.sendTime === "3" && (
                      <DatePickerComponent
                        showTimeSelect
                        autoFocus
                        label="Send every"
                        minDate={new Date()}
                        selected={this.state.sendSchedulingMonth}
                        onChange={this.handleChangeInMonth}
                      />
                    )}
                  </div>
                </div>

                <div className="m-t-20" style={{ textAlign: "end" }}>
                  <ButtonComponent
                    type="send"
                    title="Send"
                    disabled={this.state.showLoading}
                    action={this.sendEmailNotification}
                  />
                </div>
              </TabPanel>

              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>Send now</Tab>
                    <Tab>In time</Tab>
                    <Tab>Month scheduling</Tab>
                  </TabList>

                  <TabPanel>
                    <TableComponent>
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th>Sender</th>
                          <th>Time</th>
                          <th>Subject</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {showLoading ? (
                          <TableLoadingComponent />
                        ) : this.state.rec_now.length > 0 ? (
                          this.state.rec_now.map((item, key) => {
                            let createdAt = new Date(item.createdAt);
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.sender}</td>
                                <td>{formatDateEN(createdAt)}</td>
                                <td>{item.name.substring(0, 90)}</td>
                                <td>
                                  {item.is_sent ? (
                                    <span>
                                      <i
                                        className="fas fa-check"
                                        style={{
                                          color: Colors.main_active_color,
                                          marginRight: "5px",
                                        }}
                                      />
                                      Sent
                                    </span>
                                  ) : (
                                    <span>
                                      <i
                                        className="fas fa-times"
                                        style={{
                                          color: "red",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Not sent
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <TableEmptyComponent />
                        )}
                      </tbody>
                    </TableComponent>
                  </TabPanel>

                  <TabPanel>
                    <TableComponent>
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th>Sender</th>
                          <th>Email created</th>
                          <th>Send time</th>
                          <th>Subject</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {showLoading ? (
                          <TableLoadingComponent />
                        ) : this.state.rec_intime.length > 0 ? (
                          this.state.rec_intime.map((item, key) => {
                            let createdAt = new Date(item.createdAt);
                            let dateToSend = new Date(item.send_after);

                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.sender}</td>
                                <td>{formatDateEN(createdAt)}</td>
                                <td>{dateToSend.toString()}</td>
                                <td>{item.name}</td>
                                <td>
                                  {item.is_sent ? (
                                    <span>
                                      <i
                                        className="fas fa-check"
                                        style={{
                                          color: "green",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Sent
                                    </span>
                                  ) : (
                                    <span>
                                      <i
                                        className="fas fa-times"
                                        style={{
                                          color: "red",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Not sent
                                    </span>
                                  )}
                                </td>

                                <td>
                                  <button
                                    className="delete-button btn btn-danger"
                                    onClick={() =>
                                      this.openPopup("deleteInTimeEmail", item)
                                    }
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <TableEmptyComponent />
                        )}
                      </tbody>
                    </TableComponent>
                  </TabPanel>

                  <TabPanel>
                    <TableComponent>
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th>Sender</th>
                          <th>Email created</th>
                          <th>Send day</th>
                          <th>Next send time</th>
                          <th>Subject</th>
                          <th>Status</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {showLoading ? (
                          <TableLoadingComponent />
                        ) : this.state.rec_schedule.length > 0 ? (
                          this.state.rec_schedule.map((item, key) => {
                            let createdAt = new Date(item.createdAt);
                            let dateToSend = new Date(item.date_to_send);
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.sender}</td>
                                <td>{formatDateEN(createdAt)}</td>
                                <td>{item.schedule_day}</td>
                                <td>{formatDateEN(dateToSend)}</td>
                                <td>{item.name}</td>
                                <td>
                                  {item.is_sent ? (
                                    <span>
                                      <i
                                        className="fas fa-check"
                                        style={{
                                          color: "green",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Sent
                                    </span>
                                  ) : (
                                    <span>
                                      <i
                                        className="fas fa-times"
                                        style={{
                                          color: "red",
                                          marginRight: "5px",
                                        }}
                                      ></i>
                                      Not sent
                                    </span>
                                  )}
                                </td>

                                <td>
                                  {item.status == 1 ? (
                                    <button
                                      className="btn btn-success image-btn"
                                      onClick={() =>
                                        this.playPauseEmail("0", item.id, key)
                                      }
                                    >
                                      <img src={pause} />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-info image-btn"
                                      onClick={() =>
                                        this.playPauseEmail("1", item.id, key)
                                      }
                                    >
                                      <img src={play} />
                                    </button>
                                  )}
                                </td>

                                <td>
                                  <button
                                    className="delete-button btn btn-danger"
                                    onClick={() =>
                                      this.openPopup("deleteMonthShedulingEmail", item)
                                    }
                                  >
                                    <i className="fas fa-times" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <TableEmptyComponent />
                        )}
                      </tbody>
                    </TableComponent>
                  </TabPanel>
                </Tabs>
              </TabPanel>
            </Tabs>
          </div>
        ) : null}

        {this.state.popup.show && (
          <Popup
            type={this.state.popup.type}
            title={this.state.popup.title}
            popupBody={this.state.popup.popupBody}
            action={this.state.popup.action}
            onClose={this.closePopup}
          />
        )}

        <SmtpDrawer
          open={this.state.showSmtpPopup}
          onClose={() => this.setState({ showSmtpPopup: false })}
        />
      </PageComponent>
    );
  }
}

export default EmailNotifications;
