import { useState } from "react";
import { notification } from "antd";
import { useSelector } from "react-redux";

import REQUESTS from "../../../api/requests";

import UsersTable from "./UsersTable";
import UserDrawer from "./UserDrawer";
import LoginDrawer from "./LoginDrawer";
import TariffDrawer from "./TariffDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function UsersPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [isOpenLoginDrawer, setIsOpenLoginDrawer] = useState(false);

  const [userForTariff, setUserForTariff] = useState(null);

  const { permissions } = useSelector((state) => state.globalState);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.USERS.USERS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        if (!response.message) {
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
        } else {
          onSuccess(response.message);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "tariff":
        setUserForTariff(item);
        break;

      case "lock":
        REQUESTS.USERS.USERS.LOCK({ ids: item.id })
          .then((response) => {
            console.log(response);
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      case "unlock":
        REQUESTS.USERS.USERS.UNLOCK({ ids: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "archive":
        REQUESTS.USERS.USERS.ARCHIVE({ ids: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response?.message,
              });

              setGetAgain((prev) => !prev);
            } else {
              notification.error({
                message: "Error",
                description: response.message,
              });
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      case "restore":
        REQUESTS.USERS.USERS.RESTORE({ ids: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            } else {
              notification.error({
                message: "Error",
                description: response.message,
              });
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;
      default:
        return null;
      // break;
    }
  };

  return (
    <PageComponent routes={["Users", "Users"]}>
      <UsersTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
        openLoginDrawer={() => setIsOpenLoginDrawer(true)}
      />

      <TariffDrawer
        open={userForTariff ? true : false}
        user={userForTariff}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setUserForTariff(null);
        }}
      />

      <UserDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      {permissions?.login_with_code && (
        <LoginDrawer
          open={isOpenLoginDrawer}
          onClose={() => setIsOpenLoginDrawer(false)}
          getData={() => setGetAgain((prev) => !prev)}
        />
      )}
    </PageComponent>
  );
}
