import request from "../../../../api/request";
import { DEMO_API_HOST } from "../../../../config/statics";
import { getToken } from "../../../../server/requests";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    MOVIE: `${host}movie`,

    TRANSLATIONS: `${host}movie/translations`,

    TMDB: `${host}test/tmdb/`,

    NOTE: `${host}movie/not/translations`,
};

const mediaApi = {
    ADD: (body) => request("POST", URL.MOVIE, body),

    EDIT: (body) => request("PUT", URL.MOVIE, body),

    GET: (id) => request("GET", `${URL.MOVIE}?token=${getToken()}&id=${id}`),

    TMDB: {
        SEARCH: (name, language_id) =>
            request(
                "GET",
                `${URL.TMDB}serach?token=${getToken()}&q=${name}&language_id=${language_id}`
            ),

        SELECT: (type, id) =>
            request("GET", `${URL.TMDB}${type}info?token=${getToken()}&tmdbId=${id}`),

        // export const requestGetMovieInfoFromTmdb = (type, id, callback, errorCallback) => {
        //     request(
        //         "get",
        //         `${tmdbUrl}${type}info?token=${getToken()}&tmdbId=${id}`,
        //         {},
        //         callback,
        //         errorCallback,
        //         null,
        //         false
        //     );
        // };
    },

    TRANSLATIONS: {
        GET: (id, model) =>
            request("GET", `${URL.TRANSLATIONS}?token=${getToken()}&id=${id}&model=${model}`),

        ADD: (data) => request("POST", URL.TRANSLATIONS, data),

        EDIT: (data) => request("PUT", URL.TRANSLATIONS, data),
    },

    NOTES: {
        GET: (query) => request("GET", URL.NOTE, query),

        ADD: (query) => request("POST", URL.NOTE, query),

        EDIT: (query) => request("PUT", URL.NOTE, query),

        DELETE: (query) => request("DELETE", URL.NOTE, query),
    },
};

export default mediaApi;
