import { useEffect, useState } from "react";
import { Tooltip, Space, Typography } from "antd";

import icons from "../../../config/icons";

import {
    getColumnDateProps,
    getColumnSearchProps,
    getQueryDate,
    parseDate,
} from "../../../config/config";

import TableImage from "../../../_components/table/TableImage";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function FeatureTable({ getAgain, openDrawer, getDataSource, handleMenuClick }) {
    const [dataSource, setDataSource] = useState([]);

    const [loading, setLoading] = useState(false);

    const [limit, setLimit] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [total, setTotal] = useState(0);

    const [date, setDate] = useState(null);

    const [search, setSearch] = useState({
        name: null,
    });

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            key: "index",
            fixed: "left",
            render: (record, text, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
            ellipsis: {
                showTitle: false,
            },

            render: (text, record, index) => {
                return (
                    <Tooltip placement="topLeft" title={record.title} style={{ width: 150 }}>
                        <Space>
                            <TableImage src={record.image} />
                            <Typography.Paragraph
                                ellipsis={true}
                                style={{ margin: 0, width: 150, color: "gray", textAlign: "left" }}
                            >
                                {record.title}
                            </Typography.Paragraph>
                        </Space>
                    </Tooltip>
                );
            },

            ...getColumnSearchProps(),
        },

        {
            title: "Body",
            dataIndex: "body",
            key: "body",
            align: "center",
            ellipsis: {
                showTitle: false,
            },
            render: (body) => (
                <Tooltip placement="topLeft" title={body}>
                    {body}
                </Tooltip>
            ),

            ...getColumnSearchProps(),
        },

        {
            title: `Last updated`,
            dataIndex: "updatedAt",
            key: "updatedAt",
            align: "center",
            render: (text, record, index) => {
                return parseDate(text);
            },

            ...getColumnDateProps(setDate),
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this feature?`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const handleTableChange = (data) => {
        setCurrentPage(data.page);
        setLimit(data.limit);
        setSort(data.sort);

        setSearch(data.search);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                limit,
                page: currentPage,
                sort,
            };

            const queryDate = getQueryDate(date, "updatedAt");

            if (queryDate) query.between = queryDate;

            if (search.body) {
                query.search = {
                    ...query.search,
                    body: search.body[0],
                };
            }

            if (search.title) {
                query.search = {
                    ...query.search,
                    title: search.title[0],
                };
            }

            function onSuccess(data) {
                setLoading(false);

                if (!data.error) {
                    if (data?.rows?.length === 0 && currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                        return;
                    }

                    setDataSource(data?.rows);

                    setTotal(data?.total);
                    setLimit(data?.limit);
                    setCurrentPage(data?.page);
                }
            }

            function onError() {
                setLoading(false);
            }

            getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [limit, currentPage, sort, search, getAgain, date]);

    return (
        <TableComponent
            header={
                <>
                    <ButtonComponent icon={icons.ADD} title="Add Feature" onClick={openDrawer} />
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            onChange={handleTableChange}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
            scroll={{
                x: 800,
            }}
        />
    );
}
