import request from "../../../api/request";

const URL = {
    FILE: () => `${localStorage.getItem("movie_transcoder_host")}file_explorer`,
    CONNECTION: () =>
        `${localStorage.getItem("movie_transcoder_host")}connection`,
    TRANSCODINGS: () =>
        `${localStorage.getItem("movie_transcoder_host")}transcodings`,
    UPLOAD: () => `${localStorage.getItem("movie_transcoder_host")}upload`,
};

const vodTranscodingApi = {
    GET: (query) => request("GET", URL.TRANSCODINGS(), query),

    ADD: (query, onPorgress) =>
        request("POST", URL.TRANSCODINGS(), query, false, {}),

    DELETE: (query) => request("DELETE", URL.TRANSCODINGS(), query),

    START: (query) => {
        return request("POST", `${URL.TRANSCODINGS()}/start`, query, false, {});
    },

    STOP: (query) => request("POST", `${URL.TRANSCODINGS()}/stop`, query),

    STREAM_PATH: (query) =>
        request("GET", `${URL.TRANSCODINGS()}/stream_path`, query),

    STORAGE: (query) => request("POST", URL.CONNECTION(), query),

    FILE: {
        GET: (query) => request("GET", `${URL.FILE()}`, query),

        ADD: (query) => request("POST", `${URL.FILE()}/mkdir`, query),
    },

    UPLOAD: {
        STATUS: (query) => request("GET", `${URL.UPLOAD()}/status`, query),

        START: (query, header, onProgress) =>
            request(
                "POST",
                `${URL.UPLOAD()}`,
                query,
                false,
                header,
                onProgress
            ),
    },
};

export default vodTranscodingApi;

// FILE_EXPLORER: {
//     GET: (body) => {
//         let url = localStorage.getItem("movie_transcoder_host") + "file_explorer";
//         return request("get", url, body);
//     },
// },
// NEW_FOLDER: {
//     ADD: (body) => {
//         let url = localStorage.getItem("movie_transcoder_host") + "file_explorer/mkdir";
//         return request("post", url, body);
//     },
// },
