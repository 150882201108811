import { Popconfirm, Space, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";

export default function ActionsForBulk({ selectedRowKeys, setGetAgain }) {
  const bulkActions = (type, ids) => {
    switch (type) {
      case "enable":
      case "disable":
        REQUESTS.VOD.MEDIAS.ENABLE_OR_DISABLE(type, {
          id: ids.join(","),
        })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "protect":
      case "unprotect":
        REQUESTS.VOD.MEDIAS.BULK_PROTECT({
          id: ids.join(","),
          is_protected: type === "protect" ? true : false,
        })

          .then((response) => {
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }

            notification.success({
              message: "Success",
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      case "delete":
        REQUESTS.VOD.MEDIAS.DELETE({ id: ids })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  return (
    <Space>
      <Popconfirm
        disabled={selectedRowKeys.length === 0}
        placement="topRight"
        title="Do you want to protect selecteds?"
        onConfirm={(e) => bulkActions("protect", selectedRowKeys)}
        okText="Protect"
        cancelText="Cancel"
      >
        <ButtonComponent
          title="Protect"
          type="default"
          icon={icons.PROTECT}
          disabled={selectedRowKeys.length === 0}
          //   className={"protect-button"}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title="Do you want to unprotect selecteds?"
        onConfirm={(e) => bulkActions("unprotect", selectedRowKeys)}
        okText="Unprotect"
        cancelText="Cancel"
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title="Unprotect"
          type="default"
          icon={icons.UNPROTECT}
          disabled={selectedRowKeys.length === 0}
          //   className={"unprotect-button"}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title="Do you want to enable selecteds?"
        onConfirm={(e) => bulkActions("enable", selectedRowKeys)}
        okText="Enable"
        cancelText="Cancel"
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title="Enable"
          type="default"
          icon={icons.ENABLE}
          //   className={"enable-button"}
          disabled={selectedRowKeys.length === 0}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title="Do you want to disable selecteds?"
        onConfirm={(e) => bulkActions("disable", selectedRowKeys)}
        okText="Disable"
        cancelText="Cancel"
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title="Disable"
          type="default"
          icon={icons.DISABLE}
          disabled={selectedRowKeys.length === 0}
          //   className={"disable-button"}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title="Do you want to delete selecteds?"
        onConfirm={(e) => bulkActions("delete", selectedRowKeys)}
        okText="Delete"
        cancelText="Cancel"
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title="Delete"
          type="default"
          disabled={selectedRowKeys.length === 0}
          icon={icons.DELETE}
          //   className={"delete-button"}
        />
      </Popconfirm>
    </Space>
  );
}
