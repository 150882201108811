import React from "react";
import Colors from "../helpers/Colors";

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    onClickAction = () => {
        // this.setState({ loading: true });
        this.props.action();
    };

    render() {
        const { type, size, onClose, popupBody, hideButton } = this.props;
        let icon, color, button_style;

        switch (type) {
            case "Add":
                icon = "fa-plus";
                color = Colors.main_active_color;
                button_style = "add-button";
                break;

            case "Edit":
                icon = "fa-pencil-alt";
                button_style = "edit-button";
                color = "#f78f27";
                break;

            case "Archive":
                icon = "fa-archive";
                button_style = "archive-button";
                color = "#135863";
                break;

            case "Restore":
                icon = "fas fa-window-restore";
                button_style = "restore-button";
                color = "#007bff";
                break;

            case "Delete":
                color = "#dc3545";
                button_style = "remove-button";
                icon = "fa-trash-alt";
                break;

            case "Lock":
                color = "#dc3545";
                button_style = "remove-button";
                icon = "fa fa-lock ";
                break;

            case "Unlock":
                icon = "fa-unlock-alt";
                button_style = "restore-button";
                color = "#007bff";
                break;

            case "Info":
                button_style = "info-button";
                color = "#007aff";
                break;

            case "Restart":
                icon = "fa-redo";
                button_style = "restart-button";
                color = "#17a2b8";
                break;

            default:
                break;
        }

        return (
            <div className="popup-background">
                <div className="popup-empty-section" onClick={onClose} />

                <div
                    className={`popup-style ${!this.props.dontSlide && "slide-left"}`}
                    style={{ width: size || 700, overflow: "auto" }}
                >
                    <button className="close-button-style" onClick={onClose}>
                        <i className="fa fa-times" />
                    </button>

                    <div style={{ padding: "50px" }}>
                        {popupBody}

                        {!hideButton && (
                            <button
                                style={{ marginTop: 20 }}
                                className={`popup-button-style ${button_style}`}
                                disabled={this.state.loading}
                                onClick={this.onClickAction}
                            >
                                <i className={`fas ${icon}`} style={{ marginRight: 5 }} />

                                {type}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup;
