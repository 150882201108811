import LiveTvChart from "./charts/LiveTvChart";
import LiveTvDurationChart from "./charts/LiveTvDurationChart";
import { Flex } from "antd";

export default function LiveTvTab() {
  return (
    <div>
      <Flex wrap="wrap" gap="20px">
        <LiveTvChart />
        <LiveTvDurationChart />
      </Flex>
    </div>
  );
}
