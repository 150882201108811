const PATHS = {
  // a

  ADS: "/dashboard/ads",

  ADMIN: "/dashboard/admin",
  APKS: "/dashboard/apks",

  APPLICATION_BASIC: "/dashboard/basic",
  APPLICATION_BASIC_INFO: "/dashboard/basic-info/",
  APPLICATION_LANGUAGE: "/dashboard/language/",
  APPLICATION_LANGUAGES: "/dashboard/languages",
  APPLICATION_USER_ICONS: "/dashboard/user-icons",
  APPLICATION_BRANDING: "/dashboard/branding",

  // b
  // c
  CDN: "/dashboard/cdn",
  CAST: "/dashboard/cast",
  COLLECTION: "/dashboard/collection",
  COLLECTION_PAGE: "/dashboard/collection-page/",
  CONTENT_PROVIDER: "/dashboard/content-provider",
  CONTENT_PROVIDER_PROFILE: "/content-provider",
  CONTENT_PROVIDER_LOGIN: "/content-provider-login",

  CATCH_UP_SERVERS: "/dashboard/catch-up-servers",
  CATCH_UP_RECORDINGS: "/dashboard/catch-up-recordings",

  // d
  DASHBOARD: "/dashboard",
  DASHBOARD_CONTENT: "/dashboard/content",
  DOCUMENTS: "/dashboard/documents",

  // e
  EPG: "/dashboard/epg",

  EMAIL_NOTIFICATION: "/dashboard/email-notifications",
  // f
  // g
  GENRES: "/dashboard/genres",

  // h
  HISTORY: "/dashboard/history",

  // i
  IMPORT: "/dashboard/import",

  // j
  // k
  // l
  LOGIN: "/login",
  LESSONS: "/dashboard/lessons",
  LIVE_TV: "/dashboard/live-tv",
  LIVE_TV_CATEGORIES: "/dashboard/live-tv-categories",

  LAUNCHER_APPS: "/dashboard/launcher-apps",
  LAUNCHER_MESSAGES: "/dashboard/launcher-messages",
  LAUNCHER_CONFIGS: "/dashboard/launcher-configs",
  LAUNCHER_DEVICES: "/dashboard/launcher-devices",

  // m
  MESSAGES: "/dashboard/messages",
  MEDIA: "/dashboard/media/",
  MEDIA_NEW: "/dashboard/new-media/",
  MEDIA_CONTENT: "/dashboard/media_content",
  MEDIAS: "/dashboard/medias",
  MEDIA_TRANSCODING: "/dashboard/media-transcoding",

  // n
  // o
  // p
  TVOD_PAYMENT: "/dashboard/tvod-payment",
  PAYMENT_HISTORY: "/dashboard/payment-history",
  PAYMENT_SUBSCRIPTION: "/dashboard/subscription",
  PAYMENT_CONFIGS: "/dashboard/payment-configs",

  // q
  QUIZ: "/dashboard/quiz",

  // r
  RESOLUTIONS: "/dashboard/resolutions",

  // s
  SERVERS: "/dashboard/servers",
  SETTINGS: "/dashboard/settings",
  SERVICE_NOTIFICATIONS: "/dashboard/service-notifications",
  STORE_LINKS: "/dashboard/store-links",

  STREAMS: "/dashboard/streams",
  STREAM_SERVERS: "/dashboard/stream-servers",

  // t
  TRANSCODING: "/dashboard/transcoding",
  TRANSCODERS_GROUPS: "/dashboard/groups",
  TRANSCODERS_SERVERS: "/dashboard/servers",
  TRANSCODERS_RESOLUTIONS: "/dashboard/transcoding-resolutions",

  // u
  USERS: "/dashboard/users",
  USER: "/dashboard/user/",
  USERS_GROUP: "/dashboard/users-group",

  // v
  // w
  VERSIONS: "/dashboard/versions",

  WEB_HOOKS: "/dashboard/web-hooks",
  WEB_NEWS: "/dashboard/web-news",
  WEB_FAQ: "/dashboard/web-faq",
  WEB_FEATURE: "/dashboard/web-feature",
  WEB_SETTINGS: "/dashboard/web-settings",
  WEB_SLIDER: "/dashboard/web-slider",
  WEB_PLATFORMS: "/dashboard/web-platforms",
  WEB_AVAILABLE_DEVICES: "/dashboard/web-available-devices",

  // x
  // y
  // z
};

export default PATHS;
