import { useEffect, useState } from "react";

import { Drawer, Form, InputNumber, notification } from "antd";

import REQUESTS from "../../api/requests";
import ButtonComponent from "../../_components/ButtonComponent";

export default function AdsConfigsDrawer({ onClose, open }) {
    const [id, setId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        if (id) {
            const body = {
                id: id,
                max_duration: values.max_duration,
                mid_roll_ads_interval: values.mid_roll_ads_interval,
                pre_roll_ads_interval: values.pre_roll_ads_interval,
            };

            REQUESTS.ADS.CONFIGS.EDIT(body)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }

                    notification.success({
                        message: "Success",
                        description: response.message,
                    });

                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    const getData = () => {
        REQUESTS.ADS.CONFIGS.GET().then((response) => {
            if (!response.error) {
                const { mid_roll_ads_interval, max_duration, pre_roll_ads_interval, id } =
                    response.message;

                setId(id);

                form.setFields([
                    { name: "max_duration", value: max_duration },
                    { name: "mid_roll_ads_interval", value: mid_roll_ads_interval },
                    { name: "pre_roll_ads_interval", value: pre_roll_ads_interval },
                ]);
            }
        });
    };

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    return (
        <Drawer title="Ads configs" placement="right" onClose={onClose} open={open}>
            <Form
                form={form}
                name="cdn"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item label="Max duration" name="max_duration">
                    <InputNumber controls={false} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item label="Mid roll ads interval" name="mid_roll_ads_interval">
                    <InputNumber controls={false} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item label="Pre roll ads interval" name="pre_roll_ads_interval">
                    <InputNumber controls={false} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
