import { Upload } from "antd";

import defaultImage from "../img/photo.png";

export default function ImageUpload({ image, setImage, style, label }) {
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setImage({
          url: url,
          file: info.file.originFileObj,
        });
      });
    }
  };

  const imageError = (e) => (e.target.src = defaultImage);

  return (
    <Upload
      accept="image/*"
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      }}
      listType="picture"
      showUploadList={false}
      maxCount={1}
      onChange={onChange}
    >
      {image?.url ? (
        <img
          src={image?.url}
          alt="avatar"
          style={{
            width: 150,
            height: 160,
            borderRadius: 5,
            border: "1px solid #e5e7e8",
            objectFit: "cover",
            ...style,
          }}
          onError={imageError}
        />
      ) : (
        <div
          style={{
            width: 150,
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            border: "1px solid #e5e7e8",
            ...style,
          }}
        >
          {label ? label : "Upload"}
        </div>
      )}
      {/* {image?.url && (
        <div
          className="remove-image"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setImage({
              url: null,
              file: null,
            });
          }}
        >
          <i className="fa fa-trash" />
        </div>
      )} */}
    </Upload>
  );
}
