import { useEffect, useState } from "react";
import { Checkbox, Drawer, Form, Input } from "antd";

import { getSmtpNotifications, rUpdateSmtp } from "../../server/requests";

import ButtonComponent from "../../_components/ButtonComponent";

export default function SmtpDrawer({ open, onClose }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            getData();
        } else {
            setIsLoading(false);
            form.resetFields();
        }
    }, [open]);

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            name: values.name,
            username: values.username,
            password: values.password,
            port: values.port,
            smtp_host: values.smtp_host,
            sender: values.sender,
            secure: values.secure,
        };

        function callback() {
            setIsLoading(false);
            onClose();
        }

        function errorCallback() {
            setIsLoading(false);
        }

        rUpdateSmtp(body, callback, errorCallback);
    };

    const getData = () => {
        getSmtpNotifications((data) => {
            const { name, username, password, smtp_host, port, sender, secure } = data;

            form.setFields([
                { name: "name", value: name },
                { name: "username", value: username },
                { name: "password", value: password },
                { name: "smtp_host", value: smtp_host },
                { name: "port", value: port },
                { name: "sender", value: sender },
                { name: "secure", value: secure },
            ]);
        });
    };

    return (
        <Drawer open={open} placement="right" onClose={onClose} title="Smtp config" width={500}>
            <Form
                form={form}
                name="users-group"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "Please input username",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="sender"
                    rules={[
                        {
                            type: "email",
                            message: "Please enter a valid email address!",
                        },
                        {
                            required: true,
                            message: "Email is required!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    autocomplete="off"
                    rules={[
                        {
                            required: true,
                            message: "Please input password",
                        },
                    ]}
                >
                    <Input type="password" />
                </Form.Item>

                <div style={{ display: "flex", gap: 10 }}>
                    <Form.Item
                        label="Smtp host"
                        name="smtp_host"
                        rules={[
                            {
                                required: true,
                                message: "Please input smtp host",
                            },
                        ]}
                        style={{ width: "70%" }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Port"
                        name="port"
                        rules={[
                            {
                                required: true,
                                message: "Please input port",
                            },
                        ]}
                        style={{ width: "30%" }}
                    >
                        <Input />
                    </Form.Item>
                </div>

                <Form.Item name="secure" noStyle valuePropName="checked">
                    <Checkbox>SSL</Checkbox>
                </Form.Item>

                <Form.Item style={{ textAlign: "center", marginTop: 20 }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
