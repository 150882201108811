import request from "../../../api/request";
import { getToken } from "../../../server/requests";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}admin/`;

const URL = {
    SETTINGS: `${host}settings/`,

    COLOR: `${host}settings/color`,

    CONTENT_TYPE: `${host}settings/show_content_type`,

    GEOLOCATION: `${host}settings/geolocation_api`,

    LOGO: `${host}settings/logo/${getToken()}`,

    FAVICON: `${host}settings/favicon/${getToken()}`,

    NOTIFICATIONS: `${host}settings/notifications/`,

    SCHEDULED_RESTART: `${host}settings/restartTime/update`,

    SERVICE_NOTIFICATION: `${host}service_notification`,
};

const generalSettingsApi = {
    GET: () => request("POST", `${URL.SETTINGS}get`),

    SCHEDULED_RESTART: (query) => request("POST", URL.SCHEDULED_RESTART, query),

    PRIMARY_COLOR: (query) => request("PUT", URL.COLOR, query),

    LOGO: (query) => request("POST", URL.LOGO, query),

    FAVICON: (query) => request("POST", URL.FAVICON, query),

    NOTIFICATIONS: {
        GET: () => request("POST", `${URL.NOTIFICATIONS}get`),

        UPDATE: (query) => request("POST", `${URL.NOTIFICATIONS}update`, query),
    },

    GEOLOCATION: {
        GET: () => request("GET", URL.GEOLOCATION),

        UPDATE: (query) => request("POST", URL.GEOLOCATION, query),
    },

    CONTENT_TYPE: {
        GET: () => request("GET", URL.CONTENT_TYPE),

        UPDATE: (query) => request("PUT", `${URL.CONTENT_TYPE}`, query),
    },

    SERVICE_NOTIFICATION: {
        GET: (params) => request("GET", URL.SERVICE_NOTIFICATION, params),

        CLOSE: (params) => request("PUT", URL.SERVICE_NOTIFICATION, params),
    },
};

export default generalSettingsApi;
