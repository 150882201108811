import { Tag } from "antd";

import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import {
    getColumnDateProps,
    getColumnSearchProps,
    getQueryDate,
    parseDate,
} from "../../../config/config";

import TableEmpty from "../../../_components/table/TableEmpty";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function VodContentProvidersTable({
    getAgain,
    openDrawer,
    getDataSource,
    handleMenuClick,
}) {
    const [dataSource, setDataSource] = useState([]);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [sort, setSort] = useState(["id", "DESC"]);

    const [date, setDate] = useState(null);

    const [total, setTotal] = useState(0);

    const [search, setSearch] = useState({
        company_name: null,
        contact_person: null,
        email: null,
        phone_number: null,
    });

    const columns = [
        {
            title: "#",
            width: 60,
            dataIndex: `id`,
            align: "center",
            fixed: "left",
            key: "index",
            render: (record, text, index) => {
                return limit * (currentPage - 1) + index + 1;
            },
        },

        {
            title: "Status",
            key: "status",
            dataIndex: `status`,
            align: "center",
            width: 110,
            sorter: true,
            render: (text, record, index) => {
                return record.status === "active" ? (
                    <Tag color="success">Active</Tag>
                ) : (
                    <Tag color="error">Inactive</Tag>
                );
            },
        },

        {
            title: "Company name",
            dataIndex: "company_name",
            key: "company_name",
            align: "center",
            render: (text, record, index) => {
                return text || <TableEmpty />;
            },

            ...getColumnSearchProps(),
        },

        {
            title: "Contact person",
            dataIndex: "contact_person",
            key: "contact_person",
            align: "center",
            render: (text, record, index) => {
                return text || <TableEmpty />;
            },

            ...getColumnSearchProps(),
        },

        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center",
            render: (text, record, index) => {
                return text || <TableEmpty />;
            },

            ...getColumnSearchProps(),
        },

        {
            title: "Phone",
            dataIndex: "phone_number",
            key: "phone_number",
            align: "center",
            render: (text, record, index) => {
                return text || <TableEmpty />;
            },

            ...getColumnSearchProps(),
        },

        {
            title: `Created date`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            width: 180,

            render: (text, record, index) => {
                return parseDate(text);
            },

            ...getColumnDateProps(setDate),
        },

        {
            title: "",
            key: "operation",
            fixed: "right",
            width: 60,
            align: "center",

            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    items={[
                        {
                            key: "edit",
                            name: "Edit",
                            icon: icons.EDIT,
                        },

                        {
                            key: "delete",
                            name: "Delete",
                            icon: icons.DELETE,
                            question: `Do you want to delete this provider`,
                            onConfirm: (e) => {
                                handleMenuClick(e, record);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const handleTableChange = (data) => {
        setCurrentPage(data.page);
        setLimit(data.limit);
        setSort(data.sort);

        setSearch(data.search);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const query = {
                sort,
                limit,
                page: currentPage,
            };

            if (search.company_name) {
                if (query.search) {
                    query.search = {
                        ...query.search,
                        company_name: search.company_name[0],
                    };
                } else {
                    query.search = {
                        company_name: search.company_name[0],
                    };
                }
            }

            if (search.contact_person) {
                if (query.search) {
                    query.search = {
                        ...query.search,
                        contact_person: search.contact_person[0],
                    };
                } else {
                    query.search = {
                        contact_person: search.contact_person[0],
                    };
                }
            }

            if (search.email) {
                if (query.search) {
                    query.search = {
                        ...query.search,
                        email: search.email[0],
                    };
                } else {
                    query.search = {
                        email: search.email[0],
                    };
                }
            }

            if (search.phone_number) {
                if (query.search) {
                    query.search = {
                        ...query.search,
                        phone_number: search.phone_number[0],
                    };
                } else {
                    query.search = {
                        phone_number: search.phone_number[0],
                    };
                }
            }

            const queryDate = getQueryDate(date);

            if (queryDate) query.between = queryDate;

            function onSuccess(data) {
                if (data?.rows?.length === 0 && currentPage > 1) {
                    setCurrentPage((prev) => prev - 1);
                    return;
                }

                setLoading(false);
                setDataSource(data.rows);

                setTotal(data?.total);
                setLimit(data?.limit);
                setCurrentPage(data?.page);
            }

            function onError(data) {
                setLoading(false);
            }

            getDataSource(query, onSuccess, onError);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [limit, currentPage, sort, search, date, getAgain]);

    return (
        <TableComponent
            header={
                <>
                    <ButtonComponent icon={icons.ADD} title="Add Provider" onClick={openDrawer} />
                </>
            }
            isLoading={loading}
            dataSource={dataSource}
            onChange={handleTableChange}
            columns={columns}
            pagination={{
                page: currentPage,
                limit: limit,
                total: total,
            }}
            scroll={{
                x: 1200,
            }}
        />
    );
}
