import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Image, Popconfirm, Space } from "antd";

import ICONS from "../../../config/icons";
import { getColumnSearchProps } from "../../../config/config";

import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import PATHS from "../../../config/paths";
import { getAtSession, setInLocal, setInSession } from "../../../helpers/storages";

export default function LanguagesTable({
  getAgain,
  openDrawer,
  getDataSource,
  onDisable,
}) {
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(getAtSession("languages")?.page || 1);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("languages")?.search || {
      name: null,
    }
  );

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",

      render: (text, record, index) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(PATHS.APPLICATION_LANGUAGE + record.id)}
          >
            <Space>
              <Image src={record.icon} style={{ width: 25, height: 15 }} />
              <span>{record.name}</span>
            </Space>
          </div>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Popconfirm
            disabled={record.default}
            placement="topRight"
            title="Do you want to disable this language"
            onConfirm={() => onDisable(record.id)}
            okText="Disable"
            cancelText="Cancel"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button
              type="primary"
              icon={ICONS.DELETE}
              size="small"
              danger
              disabled={record.default}
            ></Button>
          </Popconfirm>
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);

    setInLocal("pageLimit", data.limit);
    setInSession("languages", {
      ...getAtSession("languages"),
      page: data.page,
      limit: data.limit,
    });

    setSearch(data.search);
  };

  // useEffect(() => {
  //     const timeout = setTimeout(() => {
  //         setLoading(true);

  //         const query = {
  //             limit,
  //             page: currentPage,
  //             sort: ["default", "DESC"],
  //             filter: {
  //                 enabled: true,
  //             },
  //         };

  //         if (search.name) {
  //             query.search = {
  //                 name: search.name[0],
  //             };
  //         }

  //         function onSuccess(data) {
  //             if (data.rows?.length === 0 && currentPage > 1) {
  //                 setCurrentPage((prev) => prev - 1);
  //                 return;
  //             }

  //             setLoading(false);
  //             setDataSource(data.rows);

  //             setTotal(data?.total);
  //             setLimit(data?.limit);
  //             setCurrentPage(data?.page);
  //         }

  //         function onError(data) {
  //             setLoading(false);
  //         }

  //         getDataSource(query, onSuccess, onError);
  //     }, 500);

  //     return () => {
  //         clearTimeout(timeout);
  //     };
  // }, [limit, currentPage, search, getAgain]);
  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        limit,
        page: currentPage,
        sort: ["default", "DESC"],
        filter: {
          enabled: true,
        },
      };

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data?.rows);
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("languages")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("languages", {
            ...getAtSession("languages"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("languages", {
            ...getAtSession("languages"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (getAtSession("languages") && getAtSession("languages")?.page > currentPage) {
        setInSession("languages", {
          ...getAtSession("languages"),
          page: 1,
        });
        setCurrentPage(getAtSession("languages")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, search, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={ICONS.ADD} title="Add Language" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
