import { Button, Form, Input, InputNumber, Select } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import NextBackButton from "../../../../_components/button/NextBackButton";
import REQUESTS from "../../../../api/requests";
import { useEffect, useState } from "react";
import { streamTypeOptions } from "../../../../config/config";

export default function SetupStreamsStep({ form, setStep }) {
  const [streamsOptions, setStreamsOptions] = useState([]);

  const getStreams = () => {
    REQUESTS.STREAMER.STREAMS.GET({
      query: JSON.stringify({ limit: 100000 }),
    }).then((response) => {
      if (response.error) return;

      const list = response.message.rows.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setStreamsOptions(list);
    });
  };

  useEffect(() => {
    getStreams();
  }, []);

  return (
    <>
      <Form.Item
        label="Stream type"
        name="stream_type"
        rules={[
          {
            required: true,
            message: "Please select type",
          },
        ]}
      >
        <Select options={streamTypeOptions} />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { stream_type } = form.getFieldsValue();

          return stream_type === "external" ? (
            <Form.Item
              label="Url"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Please input url",
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : (
            <Form.Item
              label="Sream url"
              name="stream_id"
              rules={[
                {
                  required: true,
                  message: "Please select stream",
                },
              ]}
            >
              <Select
                options={streamsOptions}
                showSearch
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLocaleLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  return (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase());
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label="Urls" shouldUpdate>
        {() => {
          return (
            <Form.List name="urls">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }) => {
                      return (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "space-between",
                            gap: 10,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "url"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input url",
                              },
                            ]}
                            style={{ width: "70%" }}
                          >
                            <Input placeholder="Enter url" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "position"]}
                            rules={[
                              {
                                required: true,
                                message: "Possition",
                              },
                            ]}
                            style={{ width: "20%" }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Possition"
                              controls={false}
                            />
                          </Form.Item>

                          <div style={{ width: "10%" }}>
                            <Button
                              icon={<MinusCircleOutlined />}
                              onClick={() => remove(name)}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add url
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          );
        }}
      </Form.Item>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <NextBackButton type="back" onClick={() => setStep("1")} />
        <NextBackButton type="next" onClick={() => setStep("3")} />
      </div>
    </>
  );
}
