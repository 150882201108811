import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ImageUpload from "../../../components/ImageUpload";
import ButtonComponent from "../../../_components/ButtonComponent";

export default function VodCollectionDrawer({ onClose, open, editable, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [icon, setIcon] = useState({
        url: null,
        file: null,
    });

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("name", values.name);

        if (icon.file) {
            formData.append("icon", icon.file);
        }

        if (editable) {
            formData.append("id", editable.id);

            REQUESTS.VOD.COLLECTIONS.EDIT(formData)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }

                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.VOD.COLLECTIONS.ADD(formData)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();

            setIsLoading(false);

            setIcon({
                url: null,
                file: null,
            });
        }

        if (editable) {
            form.setFields([{ name: "name", value: editable.name }]);

            setIcon({
                url: editable.icon,
                file: null,
            });
        }
    }, [open, editable]);

    return (
        <Drawer
            title={`${editable ? "Edit" : "Add"} collection`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                name="vod-collection"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item label="Icon" name="icon">
                    <ImageUpload image={icon} setImage={setIcon} />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
