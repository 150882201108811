import request from "../../../api/request";

import { getToken } from "../../../server/requests";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  CHANNELS: `${host}admin/channels/`,

  CHANEL_V2: `${host}v2/admin/channels`,

  SWITCH: `${host}admin/channels/switch/${getToken()}`,

  EPG: `${host}user/epg/getByDate`,

  ENABLE_ALL: `${host}v2/admin/channels/enable_all`,
};

const liveTvApi = {
  GET: (query) => request("POST", `${URL.CHANNELS}get`, query),

  // GET: (query) => request("POST", `https://api.inorain.tv:9090/get`, query),

  ADD: (query) => request("POST", `${URL.CHANNELS}add/${getToken()}`, query),

  EDIT: (query) => request("POST", `${URL.CHANNELS}update/${getToken()}`, query),

  ARCHIVE: (query) => request("POST", `${URL.CHANNELS}delete`, query),

  RESTORE: (query) => request("POST", `${URL.CHANNELS}restore`, query),

  DELETE: (query) => request("POST", `${URL.CHANNELS}delete/force`, query),

  ENABLE_DISABLE: (query) =>
    request("POST", `${URL.CHANNELS}update/${getToken()}`, query),

  POSITION: (query) => request("POST", `${URL.CHANNELS}setPosition`, query),

  ENABLE_DISABLE_BULK: (query) => request("PUT", URL.SWITCH, query),

  BULK_UPDATE: (query) => request("PUT", `${URL.CHANEL_V2}/bulk_update`, query),

  TARIFFS: (id) => request("GET", `${URL.CHANNELS}tariff?channelId=${id}`, {}),

  EPG: (query) => request("POST", URL.EPG, query),

  ENABLE_DISABLE_ALL: (query) => request("PUT", URL.ENABLE_ALL, query),
};

export default liveTvApi;
