import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification, Progress } from "antd";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import InputField from "./InputField";
import OutputField from "./OutputField";
import REQUESTS from "../../../../api/requests";

export default function VodTranscodingDrawer({ onClose, open, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [progress, setProgress] = useState(0);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        const body = {
            name: values.name,
            output: values.output,
        };

        switch (values.input_type) {
            case "select_file":
                body.input = values.select_file;
                body.input_type = "file";

                break;

            case "choose_file":
                body.input = values?.choose_file;
                body.input_type = "file";

                break;

            case "url":
                body.input = values.url;
                body.input_type = "url";

                break;

            default:
                body.input = "";
                break;
        }

        REQUESTS.VOD.TRANSCODING.ADD(body)
            .then((response) => {
                setIsLoading(false);
                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });
                    return;
                }
                getData();
                onClose();
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.message,
                });

                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (open) {
            form.resetFields();
        }
    }, [open]);

    return (
        <Drawer
            title="Transcode"
            placement="right"
            onClose={onClose}
            open={open}
            width={600}
        >
            <Form
                form={form}
                name="vod-transcode"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
                initialValues={{
                    input_type: "select_file",
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                {open && (
                    <InputField
                        form={form}
                        setProgress={setProgress}
                        progress={progress}
                    />
                )}

                {open && <OutputField form={form} />}

                {progress ? (
                    <Progress percent={progress} size="small" width={"100%"} />
                ) : (
                    ""
                )}

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        disabled={progress}
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
