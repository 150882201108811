import { Button, Form, Input, Tag } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router";

import PATHS from "../../config/paths";

import REQUESTS from "../../api/requests";

import { saveTokenToLocalStorage } from "../../config/config";

import style from "./style.module.scss";

export default function LoginPage({}) {
  const navigate = useNavigate();

  const recaptchaRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [hasContentProvider, setHasContentProvider] = useState(false);

  const [appName, setAppName] = useState("IPTV");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    if (!recaptchaValue) {
      setErrorMessage("Please verify that you are not a robot");
      setIsLoading(false);
      return;
    }
    values.recaptcha = recaptchaValue;

    if (recaptchaValue) {
      try {
        REQUESTS.PROFILE.LOGIN(values)
          .then((response) => {
            setIsLoading(false);

            if (response.error) {
              setErrorMessage(response.message);
              recaptchaRef.current.reset();
              return;
            }

            saveTokenToLocalStorage(response.message);

            navigate(PATHS.DASHBOARD_CONTENT);
          })
          .catch((err) => {
            setErrorMessage(err.message);

            setIsLoading(false);
          });
      } catch (err) {
        setErrorMessage(err.message);
        setIsLoading(false);
      }
    } else {
      recaptchaRef.current.reset();
    }
  };

  const onClickLogin = () => {
    setErrorMessage(null);

    form.submit();
  };

  const goToContentProviderLoginPage = () => {
    navigate(PATHS.CONTENT_PROVIDER_LOGIN);
  };

  const getContentProviderPermission = () => {
    REQUESTS.PROFILE.HAS_CONTENT_PROVIDER_PERMISSION()
      .then((data) => {
        if (data.error) {
          setHasContentProvider(false);
        } else {
          setHasContentProvider(data?.message?.permission);
        }
      })
      .catch((e) => {
        setHasContentProvider(false);
      });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  useEffect(() => {
    getContentProviderPermission();
    const appName = process.env.REACT_APP_NAME || "InoRain IPTV Middleware";
    setAppName(appName);
  }, []);

  return (
    <div className={style["page"]}>
      <div className={style["container"]}>
        <h2>{`Welcome to ${appName} Management admin panel.`}</h2>

        <h5>Please sign in bellow.</h5>
        <Form
          form={form}
          name="geolocation"
          layout="vertical"
          onFinish={onFinish}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              form.submit();
            }
          }}
        >
          <Form.Item label="Email">
            <Form.Item
              noStyle
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Password">
            <Form.Item
              noStyle
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form.Item>
          <Form.Item name={"recaptcha"}>
            <ReCAPTCHA
              sitekey="6LeuMmMpAAAAAHifARoCEz8uYSSxHsyn4Sz6lQ7P"
              onChange={handleRecaptchaChange}
              ref={recaptchaRef}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }} noStyle>
            <Button
              type="primary"
              onClick={onClickLogin}
              loading={isLoading}
              style={{ width: "100%" }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>

        <div className={style["error"]}>
          {errorMessage && <Tag color="red">{errorMessage}</Tag>}
        </div>
      </div>

      {hasContentProvider && (
        <div className={style["content-provider-block"]}>
          <div className={style["wrapper"]} onClick={goToContentProviderLoginPage}>
            You are a content provider
            <i className="fa fa-arrow-right" style={{ marginLeft: 10 }} />
          </div>
        </div>
      )}
    </div>
  );
}
