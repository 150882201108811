import { useEffect, useState } from "react";
import { Button, Space, Switch, Tag, Tooltip, Typography, notification } from "antd";
import REQUESTS from "../../../../api/requests";

import icons from "../../../../config/icons";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDateFormat,
  vodTypeOptions,
} from "../../../../config/config";

import FetchButton from "./FetchButton";
import ActionsForAll from "./ActionsForAll";
import ActionsForBulk from "./ActionsForBulk";

import TableEmpty from "../../../../_components/table/TableEmpty";
import TableButtons from "../../../../_components/table/TableButtons";
import TableComponent from "../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import TableImage from "../../../../_components/table/TableImage";
import {
  useCountriesOptions,
  useVodGenresOptions,
  useProvidersOptions,
} from "../../../../hooks/selectOptions";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../../helpers/storages";
import { asc_desc } from "../../../../helpers/ascDesc";
import BulkEditDrawer from "./drawer/BulkEditDrawer";
// import tablesInitialValues from "../../../../config/tablesInitialValues";

// const initialValues = tablesInitialValues.get(tablesInitialValues.keys.medias);

export default function MediasTable({
  getAgain,
  getDataSource,
  handleMenuClick,
  onEnabled,
  onHighlighted,
  setGetAgain,
  setPlaying,
  goToMediaContent,
}) {
  const genresOptions = useVodGenresOptions("text");
  const providersOptions = useProvidersOptions("text");

  const countriesOptions = useCountriesOptions("text", "id");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_vodMedias") || 10);

  const [currentPage, setCurrentPage] = useState(getAtSession("vodMedias")?.page || 1);

  const [sort, setSort] = useState(getAtSession("vodMedias")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState(
    getAtSession("vodMedias")?.search || {
      name: null,
      type: null,
    }
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [getAganaFromDrawer, setGetAgainFromDrawer] = useState(false);

  const [showEditDrawer, setShowEditDrawer] = useState(false);

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Status",
      defaultSortOrder:
        getAtSession("vodMedias")?.sort?.[0] === "is_protected" &&
        asc_desc(getAtSession("vodMedias")?.sort?.[1]),
      dataIndex: "is_protected",
      key: "is_protected",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record, index) => {
        return record.is_protected ? (
          <Tag color="green">Protected</Tag>
        ) : (
          <Tag color="volcano">No Protected</Tag>
        );
      },
    },

    {
      title: "Enable",
      defaultSortOrder:
        getAtSession("vodMedias")?.sort?.[0] === "enabled" &&
        asc_desc(getAtSession("vodMedias")?.sort?.[1]),
      dataIndex: "enabled",
      align: "center",
      key: "enabled",
      width: 100,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Switch
            size="small"
            checked={record.enabled}
            onChange={(e) => onEnabled(e, record.id)}
          />
        );
      },
    },

    {
      title: "Name",
      defaultFilteredValue: getAtSession("vodMedias")?.search?.name,
      filteredValue: getAtSession("vodMedias")?.search?.name,
      dataIndex: "name",
      key: "name",
      align: "start",
      width: 230,

      ellipsis: {
        showTitle: false,
      },

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={record.name}>
            <TableImage src={record.poster} />
            <span style={{ marginLeft: 10 }}>{record.name}</span>
          </Tooltip>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "Media type",
      defaultFilteredValue: getAtSession("vodMedias")?.search?.type,
      filteredValue: getAtSession("vodMedias")?.search?.type,
      dataIndex: "type",
      key: "type",
      align: "center",
      filters: vodTypeOptions,
      filterMultiple: false,
      width: 130,
      render: (text, record, index) => {
        return record.type === "tv_show" ? "Tv Show" : "Media";
      },
    },

    {
      title: "Url",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 260,

      render: (text, record, index) => {
        const cutText = record.stream_url?.slice(-18);
        return record.stream_url?.length ? (
          <Space>
            <Button
              style={{
                marginTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="primary"
              icon={icons.PLAY}
              onClick={() => setPlaying(record)}
            />

            <Typography.Paragraph
              style={{ margin: 0, width: 190, display: "flex" }}
              copyable={{
                text: record.stream_url,
              }}
            >
              <div style={{ width: 175 }}>
                {record.stream_url?.length < 18 ? "" : "..."} {cutText}
              </div>
            </Typography.Paragraph>
          </Space>
        ) : (
          <TableEmpty />
        );
      },
    },

    {
      title: "Genres",
      defaultFilteredValue: getAtSession("vodMedias")?.search?.genres,
      filteredValue: getAtSession("vodMedias")?.search?.genres,
      dataIndex: "genres",
      key: "genres",
      align: "center",
      ellipsis: true,
      filters: genresOptions,
      filterMultiple: false,
      width: 200,
      render: (text, record, index) => {
        const genresNames = record.genres?.map((genre) => {
          return genre.name;
        });

        const list = genresNames.join(", ");

        return (
          <Tooltip placement="topLeft" title={list}>
            <span>{list}</span>
          </Tooltip>
        );
      },
    },

    {
      title: "Countries",
      defaultFilteredValue: getAtSession("vodMedias")?.search?.country_id,
      filteredValue: getAtSession("vodMedias")?.search?.country_id,
      dataIndex: "country_id",
      key: "country_id",
      align: "center",
      ellipsis: true,
      filters: countriesOptions,
      filterMultiple: false,
      width: 200,
      render: (text, record, index) => {
        const countriesNames = record.countries?.map((country) => {
          return country.name;
        });

        const list = countriesNames.join(", ");

        return (
          <Tooltip placement="topLeft" title={list}>
            <span>{list}</span>
          </Tooltip>
        );
      },
    },

    {
      title: "Provider",
      defaultFilteredValue: getAtSession("vodMedias")?.search?.content_provider_id,
      filteredValue: getAtSession("vodMedias")?.search?.content_provider_id,
      dataIndex: "content_provider_id",
      key: "content_provider_id",
      align: "center",
      ellipsis: true,
      filters: providersOptions,
      filterMultiple: false,
      width: 200,
      render: (text, record, index) => {
        return <div>{record?.content_provider?.contact_person || <TableEmpty />}</div>;
      },
    },

    {
      title: "Release Date",
      defaultFilteredValue: getAtSession("vodMedias")?.search?.release_date,
      dataIndex: "release_date",
      align: "center",
      width: 140,
      render: (text, record, index) => {
        return record?.release_date ? (
          parseDateFormat(record?.release_date)
        ) : (
          <TableEmpty />
        );
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "License end date",
      dataIndex: "release_date",
      align: "center",
      width: 140,
      render: (text, record, index) => {
        return record?.license_ending_date ? (
          parseDateFormat(record?.license_ending_date)
        ) : (
          <TableEmpty />
        );
      },
    },

    {
      title: "Highlighted",
      defaultSortOrder:
        getAtSession("vodMedias")?.sort?.[0] === "highlighted" &&
        asc_desc(getAtSession("vodMedias")?.sort?.[1]),
      dataIndex: "highlighted",
      align: "center",
      key: "highlighted",
      width: 100,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Switch
            size="small"
            checked={record.highlighted}
            onChange={(e) => onHighlighted(e, record.id)}
          />
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            record.is_protected
              ? {
                  key: "unprotect",
                  name: "Unprotect",
                  icon: icons.UNPROTECT,
                  question: `Do you want to unprotect this category?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                }
              : {
                  key: "protect",
                  name: "Protect",
                  icon: icons.PROTECT,
                  question: `Do you want to protect this category?`,
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                },

            {
              key: "gallery",
              name: "Gallery",
              icon: icons.GALLERY,
            },

            // {
            //     key: "soundtrack",
            //     name: "Soundtrack",
            //     icon: icons.SOUNDTRACKS,
            // },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this live tv?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const vodMedias = {
      page: data?.page,
      search: data?.search,
    };
    if (data?.sort) {
      vodMedias.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_vodMedias", data.limit);
    setInSession("vodMedias", vodMedias);

    setCurrentPage(data?.page);
    setLimit(data?.limit);
    setSort(data?.sort);
    setSearch(data?.search);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFinish = (values, fieldChecked) => {
    const body = {
      ids: selectedRowKeys.join(","),
    };

    if (fieldChecked.age_restriction_enable) {
      body.pg = values["age_restriction"];
    }

    if (fieldChecked.tariff_enable) {
      if (
        values.content_monetization_type === "stv" ||
        (values.content_monetization_type === "tvod" && fieldChecked.tariff_enable)
      ) {
        body.tariff_ids = values["tariffIds"].join(",");
        body.content_monetization_type = values["content_monetization_type"];
      } else if (
        values.content_monetization_type === "free" &&
        fieldChecked.tariff_enable
      ) {
        body.content_monetization_type = values["content_monetization_type"];
      }
    }

    // if (fieldChecked.devices_enable) {
    //   body.permission_devices = values["deviceIds"].join(",");
    // }

    if (fieldChecked.location_restriction_enable) {
      body.available_countries = values["countries"]?.join(",");
      body.is_location_restriction = values["location_restriction_enable"];
    }

    if (fieldChecked.is_protected_enable) {
      body.is_protected = values["protected"] ? values["protected"] : false;
    }

    if (fieldChecked.categories_enable) {
      body.categories_ids = values["categories"].join(",");
    }

    REQUESTS.VOD.MEDIAS.BULK_UPDATE(body)
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });
          setShowEditDrawer(false);

          setGetAgainFromDrawer((prev) => !prev);
        } else {
          notification.error({
            message: "Error",
            description: response?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (search.type) {
        query.filter = {
          type: search.type[0],
        };
      }

      if (search.genres) {
        query.filter = {
          genre_id: search.genres[0],
        };
      }

      if (search.country_id) {
        query.filter = {
          country_id: search.country_id[0],
        };
      }

      if (search.content_provider_id) {
        query.filter = {
          content_provider_id: search.content_provider_id[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data?.rows);
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("vodMedias")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("vodMedias", {
            ...getAtSession("vodMedias"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("vodMedias", {
            ...getAtSession("vodMedias"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (getAtSession("vodMedias") && getAtSession("vodMedias")?.page > currentPage) {
        setInSession("vodMedias", {
          ...getAtSession("vodMedias"),
          page: 1,
        });
        setCurrentPage(getAtSession("vodMedias")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain, getAganaFromDrawer]);

  return (
    <TableComponent
      header={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <FetchButton /> */}
            <ActionsForAll setGetAgain={setGetAgain} />

            <ButtonComponent
              icon={icons.ADD}
              title="Add Movie"
              onClick={goToMediaContent}
            />
          </Space>
          <Space>
            {selectedRowKeys.length > 0 && (
              <div style={{ display: "flex", marginTop: 20, gap: 10 }}>
                <ActionsForBulk
                  selectedRowKeys={selectedRowKeys}
                  setGetAgain={setGetAgain}
                />
                <ButtonComponent
                  icon={icons.EDIT}
                  title="Edit"
                  type="default"
                  onClick={() => setShowEditDrawer(true)}
                />
              </div>
            )}
            <BulkEditDrawer
              open={showEditDrawer}
              onClose={() => setShowEditDrawer(false)}
              finish={handleFinish}
            />
          </Space>
        </div>
      }
      rowSelection={rowSelection}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      rowClassName={(row) => {
        if (row.archive) return "red-row";
      }}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1800,
      }}
    />
  );
}
