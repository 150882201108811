import React, { useState, useEffect } from "react";

import Poster from "./Poster";
import Search from "./Search";
import Backdrop from "./Backdrop";
import Rating from "./Rating";
import Translations from "./Translations";

import Seasons from "./Seasons";
import SeasonPopup from "./SeasonPopup";

import Series from "./Series";
import SeriePopup from "./SeriePopup";

import InputComponent from "../../../../components/InputComponent";
import ButtonComponent from "../../../../components/ButtonComponent";
import SelectComponent from "../../../../components/SelectComponent";
import TextareaComponent from "../../../../components/TextareaComponent";
import DatePickerComponent from "../../../../components/DatePickerComponent";

import QuestionPopup from "../../../../popups/QuestionsPopup";

import { closeActionsBlock } from "../../../../components/ActionsBlock";

import requests, {
  requestDeleteSeason,
  requestDeleteSerie,
  requestGetMovieInfoFromTmdb,
  requestMovieById,
  requestRatingById,
  requestGenresById,
  requestSeasonsById,
  requestEpisodesById,
  requestNotesById,
} from "../../../../server/requests";

import "./_media.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import UrlWithPlayer from "./UrlWithPlayer";
import VideoComponent from "../../../../components/VideoComponent";
import { inoToast } from "../../../../components/toast/Toast";
import Notes from "./Notes";
import { isValidDate, converterMSToYMD } from "../../../../helpers/formats";
import PATHS from "../../../../config/paths";
import REQUESTS from "../../../../api/requests";
import { InputNumber, Modal, Select } from "antd";
import { streamTypeOptions } from "../../../../config/config";
import TranscodingSearch from "../../../../_components/searches/TranscodingSearch";
import { AGE_RESTRICTION } from "../../../../config/statics";

const optionsOfMediaType = [
  {
    label: "Movie",
    value: "movie",
  },
  {
    label: "TV-Show",
    value: "tv_show",
  },
];

export default function MediaPage() {
  const params = useParams();
  const navigate = useNavigate();

  const editableMovieId = params.id;

  const [loading, setLoading] = useState(false);

  const [tmdbId, setTmdbId] = useState("");

  const [id, setId] = useState(null);
  const [name, setName] = useState("");

  const [mediaType, setMediaType] = useState(optionsOfMediaType[0]);

  const [poster, setPoster] = useState(null);
  const [backdrop, setBackdrop] = useState("");
  const [imageTitle, setImageTitle] = useState(null);
  const [posterTv, setPosterTv] = useState(null);

  const [tvHighlight, setTvHighlight] = useState(null);
  const [mobileHighlight, setMobileHighlight] = useState(null);

  const [description, setDescription] = useState("");

  const [note, setNote] = useState("");
  const [duration, setDuration] = useState(0);
  const [price, setPrice] = useState(0);
  const [pg, setPg] = useState(AGE_RESTRICTION);

  const [releaseDate, setReleaseDate] = useState("");

  const [streamType, setStreamType] = useState(streamTypeOptions[0]);

  const [streamUrl, setStreamUrl] = useState("");

  const [selectedTranscoding, setSelectedTranscoding] = useState(null);
  const [selectedTranscodingId, setSelectedTranscodingId] = useState(null);

  const [trailerStreamType, setTrailerStreamType] = useState(streamTypeOptions[0]);

  const [trailerUrl, setTrailerUrl] = useState("");

  const [selectedTranscodingForTrailer, setSelectedTranscodingForTrailer] =
    useState(null);
  const [selectedTranscodingIdForTrailer, setSelectedTranscodingIdForTrailer] =
    useState(null);

  const [genres, setGenres] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [optionsOfGenre, setOptionsOfGenre] = useState([]);
  const [optionsOfPricing, setOptionsOfPricing] = useState([]);

  const [countries, setCountries] = useState([]);
  // const [availableCountries, setAvailableCountries] = useState([]);
  const [optionsOfCountry, setOptionsOfCountry] = useState([]);

  const [isLocationRestriction, setIsLocationRestriction] = useState(false);
  const [availableCountries, setAvailableCountries] = useState([]);

  const [rating, setRating] = useState({
    tmdb: 0,
    metacritic: 0,
    rotten_tomatos: 0,
    imdb: 0,
  });

  const [casts, setCasts] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [currentSeasonIndex, setCurrentSeasonIndex] = useState(0);

  const [editableSeason, setEditableSeason] = useState(null);
  const [deletableSeason, setDeletableSeason] = useState(null);
  const [visibleTheSeasonPopup, setVisibleTheSeasonPopup] = useState(false);

  const [editableSerie, setEditableSerie] = useState(null);
  const [deletableSerie, setDeletableSerie] = useState(null);
  const [visibleTheSeriePopup, setVisibleTheSeriePopup] = useState(false);

  const [providersOption, setProvidersOption] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [provider, setProvider] = useState(null);

  const [contentMonetizationTypeOption, setContentMonetizationTypeOption] = useState({
    free: false,
    svod: false,
    tvod: false,
  });

  const [introStartTime, setIntroStartTime] = useState(0);
  const [introEndTime, setIntroEndTime] = useState(0);
  const [creditsTime, setCreditsTime] = useState(0);

  const [videoSrc, setVideoSrc] = useState(null);

  const [licenseEndingDate, setlicenseEndingDate] = useState("");

  const [originalAudioLanguage, setOriginalAudioLanguage] = useState("");

  const [languageId, setLanguageId] = useState(null);

  const [permissionMenu, setPermissionMenu] = useState(null);

  const [svodOptions, setSvodOptions] = useState([]);

  const [selectedSvod, setSelectedSvod] = useState([]);

  const [isProtected, setIsProtected] = useState(false);

  const [getAgain, setGetAgain] = useState(false);

  useEffect(() => {
    const getMediaDetails = async (movieId) => {
      const queries = {
        movieDetails: { id: movieId },
        ratings: { id: movieId },
        genres: { id: movieId },
        season: { id: movieId },
        notes: { id: movieId },
      };

      try {
        const [movie, rating, genre, seasons, notes] = await Promise.all([
          fetchData(requestMovieById, queries.movieDetails),
          fetchData(requestRatingById, queries.ratings),
          fetchData(requestGenresById, queries.genres),
          fetchData(requestSeasonsById, queries.season),
          fetchData(requestNotesById, queries.notes),
        ]);

        const episodePromises = seasons?.map((season) => {
          return fetchData(requestEpisodesById, { id: season?.id })
            .then((episodes) => {
              season.episodes = episodes;
              return season;
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });

        const episodes = await Promise.all(episodePromises);
        setEpisodesData(episodes);
        setMovieDetails(movie);
        setRatings(rating);
        setSeason(episodes);
        setNotes(notes);

        let newGenres = genre?.map(
          (genre) =>
            (genre = {
              label: genre.name,
              value: genre.name,
            })
        );
        setGenres(newGenres);

        getListOfGenre();

        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (editableMovieId) {
      getMediaDetails(editableMovieId);
      getPermissionMenu();
    } else {
      getListOfCountry();
      // getProvidersOption();
      getPricingList();
      getPermissionMenu();
    }
  }, [editableMovieId, getAgain]);

  const setMovieDetails = (movie) => {
    if (movie?.stream_type) {
      const streamType = streamTypeOptions.find(
        (item) => item.value === movie.stream_type
      );

      setStreamType(streamType);
    }

    if (movie?.trailer_stream_type) {
      const trailerStreamType = streamTypeOptions.find(
        (item) => item.value === movie.trailer_stream_type
      );

      setTrailerStreamType(trailerStreamType);
    }

    if (movie?.transcoding_id) {
      setSelectedTranscodingId(movie.transcoding_id);
    }

    if (movie?.trailer_transcoding_id) {
      setSelectedTranscodingIdForTrailer(movie.trailer_transcoding_id);
    }

    if (movie?.available_countries) {
      let availableCountries = movie?.available_countries?.split(",");
      let curentList = availableCountries?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      setAvailableCountries(curentList);
    }
    if (movie?.license_ending_date) {
      let license_ending_date = new Date(movie.license_ending_date);

      if (isValidDate(license_ending_date)) {
        setlicenseEndingDate(license_ending_date);
      }
    }
    setId(movie?.id);
    setName(movie?.name);

    setPg(movie.pg || AGE_RESTRICTION);

    setCasts(movie.casts);

    setStreamUrl(movie.stream_url);

    setPoster(movie.poster);
    setBackdrop(movie?.backdrop || "");
    setImageTitle(movie.image_title);
    setPosterTv(movie.poster_tv);
    setTvHighlight(movie.highlight_tv);
    setMobileHighlight(movie.highlight_mobile);

    setDescription(movie.description);
    setNote(note);
    setIsLocationRestriction(movie.is_location_restriction);
    setIsProtected(movie.is_protected);
    setCreditsTime(movie.credits_time || 0);
    setIntroStartTime(movie.intro_start_time || 0);
    setIntroEndTime(movie.intro_end_time || 0);

    setTrailerUrl(movie.trailer_url);

    setDuration(movie.duration);

    setOriginalAudioLanguage(movie.original_audio_language);

    if (movie.release_date) {
      setReleaseDate(new Date(movie.release_date));
    }
    setPrice(movie.price);

    const mediaType = optionsOfMediaType?.find((item) => item.value === movie.type);

    setMediaType(mediaType);

    setContentMonetizationTypeOption((types) => ({
      ...types,
      [movie.content_monetization_type]: true,
    }));

    getMovieTariff(movie.id);
    getSvodTariffs();

    getPricingList((list) => {
      let pricing = [];
      const wanted = list.find((item) => item?.value[0] === movie?.pricing_id);

      if (wanted) {
        pricing.push(wanted);
      }
      setPricing(pricing);
    });

    getListOfCountry((list) => {
      let countries = [];

      for (let i = 0; i < movie.countries.length; i++) {
        const wanted = list.find((item) => item.value === movie.countries[i].name);

        if (wanted) {
          countries.push(wanted);
        }
      }
      getProvidersOption((data) => {
        const newProvider = data.filter(
          (item) => item.value === movie.content_provider_id
        );
        setProvider(newProvider);
        setProvidersOption(data);
      });

      setCountries(countries);
    });
  };
  
  const setRatings = (rating) => {
    if (rating?.length) {
      rating?.map((item) => {
        setRating({
          tmdb: item.tmdb || 0,
          metacritic: item.metacritic || 0,
          rotten_tomatos: item.rotten_tomatos || 0,
          imdb: item.imdb || 0,
        });
      });
    }
  };

  const setSeason = (data) => {
    setSelectedSeason(data[currentSeasonIndex]);
  };
  const setNotes = (data) => {
    setNote(data);
  };

  const setEpisodesData = (data) => {
    setSeasons(data);
  };

  const fetchData = async (requestFunction, query) => {
    if (!requestFunction) return;

    return await new Promise((resolve, reject) => {
      requestFunction(query, (data) => {
        if (data) {
          resolve(data || []);
        } else {
          reject(new Error("Data not available"));
        }
      });
    });
  };

  const getPermissionMenu = () => {
    requests.permission_menu({}, (data) => {
      setPermissionMenu(data);
    });
  };

  const openPopup = (type, item) => {
    closeActionsBlock();

    switch (type) {
      case "addSeason":
        setVisibleTheSeasonPopup(true);
        break;

      case "editSeason":
        setEditableSeason(item);

        setVisibleTheSeasonPopup(true);
        break;

      case "deleteSeason":
        setDeletableSeason(item);
        break;

      case "addSerie":
        setVisibleTheSeriePopup(true);

        break;

      case "editSerie":
        setEditableSerie(item);
        setVisibleTheSeriePopup(true);

        break;

      case "deleteSerie":
        setDeletableSerie(item);
        break;

      default:
        break;
    }
  };

  const onClickAddOrEdit = (event) => {
    if (parseInt(introStartTime) > parseInt(introEndTime)) {
      inoToast.error("Check intro end time");
      return;
    }

    if (parseInt(introStartTime) < 0) {
      inoToast.error("Check intro start time");
      return;
    }

    if (parseInt(introEndTime) < 0) {
      inoToast.error("Check intro end time");
      return;
    }

    if (parseInt(creditsTime) < 0) {
      inoToast.error("Check credits time");
      return;
    }

    let newGenres = genres?.map((genre) => genre.value).join(",");
    let newCountries = countries.map((country) => country.value).join(",");
    let newAvailableCountries = availableCountries.map((el) => el.value).join(",");

    let formData = new FormData();

    if (editableMovieId) {
      formData.append("id", id);
    }

    formData.append("type", event.value ? event.value : mediaType.value);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("note", note);
    formData.append("duration", duration);
    // formData.append("price", price);

    if (originalAudioLanguage) {
      formData.append("original_audio_language", originalAudioLanguage);
    }

    if (licenseEndingDate) {
      //TODO  In the backend license ending date must be a date or empty text
      formData.append("license_ending_date", licenseEndingDate);
    }

    formData.append("stream_type", streamType.value);
    formData.append("trailer_stream_type", trailerStreamType.value);

    if (streamType.value === "internal") {
      formData.append("stream_url", "");
      formData.append("transcoding_id", selectedTranscodingId);
    } else {
      formData.append("stream_url", streamUrl);
      formData.append("transcoding_id", "");
    }

    if (trailerStreamType.value === "internal") {
      formData.append("trailer_url", "");
      formData.append("trailer_transcoding_id", selectedTranscodingIdForTrailer);
    } else {
      formData.append("trailer_url", trailerUrl);
      formData.append("trailer_transcoding_id", "");
    }

    formData.append("genres", newGenres);
    formData.append("pricing_id", pricing?.value || "");
    formData.append("countries", newCountries);
    formData.append("credits_time", creditsTime);
    formData.append("intro_start_time", introStartTime);
    formData.append("intro_end_time", introEndTime);

    formData.append("is_location_restriction", isLocationRestriction);
    formData.append("is_protected", isProtected);
    if (isLocationRestriction) {
      formData.append("available_countries", newAvailableCountries);
    }
    for (let key in contentMonetizationTypeOption) {
      if (contentMonetizationTypeOption[key]) {
        formData.append("content_monetization_type", key);
      }
    }

    formData.append("casts", JSON.stringify(casts));

    formData.append("rating", JSON.stringify(rating));

    if (pg) formData.append("pg", pg);
    if (releaseDate) formData.append("release_date", converterMSToYMD(releaseDate));
    if (selectedProvider) formData.append("content_provider_id", selectedProvider.value);

    if (tmdbId && typeof poster !== "object") {
      formData.append(
        "tmdb_poster",
        poster.replace("https://image.tmdb.org/t/p/original/", "")
      );
    } else if (typeof poster === "object") {
      formData.append("poster", poster ? poster : " ");
    }

    if (tmdbId && typeof backdrop !== "object") {
      let tmdbBackDrop = "";

      if (!backdrop.endsWith("null")) {
        tmdbBackDrop = backdrop.replace("https://image.tmdb.org/t/p/original/", "");
      }

      formData.append("tmdb_backdrop", tmdbBackDrop || "");
    } else if (typeof backdrop === "object") {
      formData.append("backdrop", backdrop ? backdrop : "");
    }

    if (typeof imageTitle === "object") formData.append("image_title", imageTitle || " ");
    if (typeof posterTv === "object") formData.append("poster_tv", posterTv || " ");

    if (typeof tvHighlight === "object")
      formData.append("highlight_tv", tvHighlight || " ");

    if (typeof mobileHighlight === "object")
      formData.append("highlight_mobile", mobileHighlight || " ");

    if (selectedSvod?.length) {
      formData.append("tariffIds", selectedSvod.join(","));
    }

    if (editableMovieId) {
      requests.vod.media.edit(formData, (data) => {
        // fetchData(requestMovieById, { id: data.id });

        setGetAgain((prev) => !prev);
      });
    } else {
      if (tmdbId) {
        formData.append("tmdb_id", tmdbId);
      }

      requests.vod.media.add(formData, (data) => {
        navigate(`${PATHS.MEDIA}${data.id}`);
        window.location.reload();
      });
    }
  };

  const selectMovieFromTmdb = (item) => {
    setLoading(true);
    requestGetMovieInfoFromTmdb(item.media_type, item.id, languageId, (data) => {
      setTmdbId(data.id);
      if (data.rating) {
        let imdb = "";
        let rotten_tomatos = "";
        let metacritic = "";

        for (let i = 0; i < data.rating.length; i++) {
          let { Source, Value } = data.rating[i];

          switch (Source) {
            case "Internet Movie Database":
              imdb = Value;
              break;
            case "Rotten Tomatoes":
              rotten_tomatos = Value;
              break;
            case "Metacritic":
              metacritic = Value;
              break;
          }
        }
        setRating((prev) => ({
          ...prev,
          imdb,
          metacritic,
          rotten_tomatos,
        }));
      }

      setRating((prev) => ({
        ...prev,
        tmdb: data.vote_average || 0,
      }));

      // let newCast = [];

      // for (let i = 0; i < data.credits.cast.length; i++) {
      //   newCast.push({
      //     name: data.credits.cast[i].name,
      //     tmdb_id: data.credits.cast[i].id,
      //   });
      // }

      // for (let i = 0; i < data.credits.crew.length; i++) {
      //   newCast.push({
      //     name: data.credits.crew[i].name,
      //     tmdb_id: data.credits.crew[i].id,
      //   });
      // }

      // setCasts(newCast);

      let newCountries = [];

      if (data.origin_country) {
        for (let i = 0; i < optionsOfCountry.length; i++) {
          for (let j = 0; j < data.origin_country.length; j++) {
            if (optionsOfCountry[i].value === data.origin_country[j]) {
              newCountries.push(optionsOfCountry[i]);
            }
          }
        }
      }

      if (data.production_countries) {
        for (let i = 0; i < optionsOfCountry.length; i++) {
          for (let j = 0; j < data.production_countries.length; j++) {
            if (optionsOfCountry[i].label === data.production_countries[j].name) {
              newCountries.push(optionsOfCountry[i]);
            }
          }
        }
      }

      setCountries(newCountries);

      let type = {
        label: item.media_type === "movie" ? "Movie" : "TV-Show",
        value: item.media_type === "movie" ? "movie" : "tv_show",
      };

      setMediaType(type);

      let newGenres = data?.genres?.map(
        (genre) =>
          (genre = {
            label: genre.name,
            value: genre.name,
          })
      );
      setCreditsTime(0);
      setIntroStartTime(0);
      setIntroEndTime(0);

      setTrailerUrl("");
      // console.log("newGenres", newGenres);
      setGenres(newGenres);
      setPricing(pricing);

      setName(data.name || data.title);

      if (item.overview) {
        setDescription(item.overview);
      } else {
        setDescription(data.overview);
      }
      if (data.runtime) {
        setDuration(data.runtime);
      }

      if (data.episode_run_time && data.episode_run_time[0]) {
        setDuration(data.episode_run_time[0]);
      }

      if (data.release_date) {
        let showReleaseDate = new Date(data?.release_date);
        setReleaseDate(showReleaseDate);
      }

      if (data.first_air_date) {
        let first_air_date = new Date(data?.first_air_date);

        setReleaseDate(first_air_date);
      }

      setPg(AGE_RESTRICTION);
      // setPrice(0);

      setImageTitle(null);

      setPosterTv(null);
      setTvHighlight(null);
      setMobileHighlight(null);

      setContentMonetizationTypeOption((types) => ({
        ...types,
        FREE: true,
      }));

      setBackdrop("https://image.tmdb.org/t/p/original/" + data.backdrop_path || "");
      setPoster("https://image.tmdb.org/t/p/original/" + data.poster_path);

      setLoading(false);
    });
  };

  const getMovieTariff = (id) => {
    requests.vod.media.TARIFF(id, (data) => {
      if (data) {
        const selecteds = data.map((item) => item.id);
        setSelectedSvod(selecteds);
      }
    });
  };

  const getListOfGenre = (callback) => {
    requests.vod.genres.get((data) => {
      const list = data.data.map((item) => ({
        label: item.name,
        value: item.name,
        isProtected: item.is_protected,
      }));

      setOptionsOfGenre(list);
      //
      callback && callback(list);
    });
  };

  const getSvodTariffs = () => {
    REQUESTS.PAYMENTS.SUBSCRIPTIONS.GET().then((response) => {
      if (response.message) {
        const data = response.message.rows;

        const list = data.map((item) => {
          return {
            label: item?.name,
            value: item.id,
          };
        });

        setSvodOptions(list);
      }
    });
  };

  const getListOfCountry = (callback) => {
    requests.countries.get((data) => {
      const list = data.map((item) => ({
        label: item.name,
        value: item.name,
      }));

      setOptionsOfCountry(list);

      callback && callback(list);
    });
  };

  const getProvidersOption = (callback) => {
    requests.content_provider.get({}, (data) => {
      const list = data?.map((item) => ({
        label: item.company_name,
        value: item.id,
      }));

      callback && callback(list);
    });
  };

  const getPricingList = (callback) => {
    REQUESTS.PAYMENTS.TVOD_PAYMENT.GET().then((response) => {
      if (response.message) {
        const data = response?.message?.rows;
        const list = data.map((item) => {
          return {
            label: item?.name,
            value: item.id,
          };
        });
        setOptionsOfPricing(list);
        callback && callback(list);
      }
    });
  };

  const closeSeasonPopup = () => {
    setVisibleTheSeasonPopup(false);
    setEditableSeason(null);
  };

  const closeSeriePopup = () => {
    setVisibleTheSeriePopup(false);
    setEditableSerie(null);
  };

  const deleteSeason = () => {
    requestDeleteSeason(deletableSeason.id, () => {
      setCurrentSeasonIndex(0);
      setGetAgain((prev) => !prev);
      // fetchData(requestMovieById, { id: id });

      setDeletableSeason(null);
    });
  };

  const deleteSerie = () => {
    requestDeleteSerie(deletableSerie.id, () => {
      setGetAgain((prev) => !prev);

      setDeletableSerie(null);
    });
  };

  const selectSeason = (selected, key) => {
    setSelectedSeason(selected);
    setCurrentSeasonIndex(key);
  };

  const selectContentMonetizationType = (event) => {
    const newObj = { ...contentMonetizationTypeOption };

    for (let key in newObj) {
      if (event.target.name === key) {
        newObj[event.target.name] = !newObj[event.target.name];
      } else {
        newObj[key] = false;
      }
    }

    setContentMonetizationTypeOption(newObj);
  };

  const onChangeMediaType = (e) => {
    setMediaType(e);
  };

  const saveSkeepOption = (option) => {
    setIntroStartTime(option.start);
    setIntroEndTime(option.end);
    setCreditsTime(option.credits);
    setDuration(option.duration);
    if (option.originalAudioTrack) {
      setOriginalAudioLanguage(option.originalAudioTrack.label);
    }
    setVideoSrc(null);
  };

  const onChangeSkeep = (event) => {
    let value = event.target.value;

    if (value) {
      value = Number(value);
    }

    switch (event.target.name) {
      case "intro_start_time":
        if (value > introEndTime) {
          inoToast.error("Intro start time can't be big from Intro end time");

          return;
        }
        setIntroStartTime(value);

        break;

      case "intro_end_time":
        if (value > creditsTime) {
          inoToast.error("Intro end time can't be big from Credits time");

          return;
        }

        if (value < introStartTime) {
          inoToast.error("Intro end time can't be less from Intro start time");

          return;
        }

        setIntroEndTime(value);

        break;

      case "credits_time":
        if (value < introEndTime) {
          inoToast.error("Credits time can't be less from Intro end time");

          return;
        }

        setCreditsTime(value);

        break;

      case "duration":
        setDuration(value);

      default:
        break;
    }
  };

  const onProtect = (event) => {
    const checked = event.target.checked;

    if (genres.length > 0) {
      const isProtectedGenres = genres.filter((item) => item.isProtected);

      if (isProtectedGenres.length) {
        setIsProtected(true);

        if (checked === false) {
          Modal.confirm({
            title: "Are you sure?",
            content:
              "Are you sure you want to remove this movie from the protected list? This movie is currently included in the protected genre and removing it may result in unintended consequences. Please proceed with caution and ensure that you have a good reason for removing this movie from the protected list before making any changes.",
            okText: "Yes",
            onOk() {
              setIsProtected(false);
            },
            onCancel() {
              setIsProtected(true);
            },
          });
        }
      } else {
        setIsProtected(checked);
      }
    }
  };

  const selectGenre = (option, single) => {
    setGenres(option);

    if (single.option && single.option.isProtected) {
      setIsProtected(true);
    }
  };

  const generateUrlFromTranscoding = () => {
    REQUESTS.VOD.TRANSCODING.GET({
      query: JSON.stringify({
        filter: {
          id: selectedTranscodingId,
        },
      }),
    }).then((response) => {
      if (response?.rows) {
        const transcoding = response.rows[0];

        setSelectedTranscoding(transcoding);
      }
    });
  };

  const generateUrlFromTranscodingForTrailer = () => {
    REQUESTS.VOD.TRANSCODING.GET({
      query: JSON.stringify({
        filter: {
          id: selectedTranscodingIdForTrailer,
        },
      }),
    }).then((response) => {
      if (response?.rows) {
        const transcoding = response.rows[0];

        setSelectedTranscodingForTrailer(transcoding);
      }
    });
  };

  useEffect(() => {
    if (selectedTranscodingId) {
      generateUrlFromTranscoding();
    }
  }, [selectedTranscodingId]);

  useEffect(() => {
    if (selectedTranscodingIdForTrailer) {
      generateUrlFromTranscodingForTrailer();
    }
  }, [selectedTranscodingIdForTrailer]);

  return (
    <>
      <div className="media-page" style={{ filter: `blur(${loading ? "10px" : 0}` }}>
        <div
          className="pointer fs-25"
          onClick={() => {
            navigate(PATHS.MEDIAS);
          }}
        >
          <i className="fas fa-arrow-left mr-1" />
          Back to movies
        </div>
        <div className="header-section">
          <SelectComponent
            label="Media type"
            value={mediaType}
            options={optionsOfMediaType}
            change={onChangeMediaType}
          />

          <Search
            value={name}
            onChange={setName}
            select={selectMovieFromTmdb}
            mediaType={mediaType}
            languageId={(id) => {
              setLanguageId(id);
            }}
          />
        </div>

        <div className="section-style">
          <div className="block-style">
            <div className="image-title">
              <div className="poster-block">
                <div className="image-label">
                  <label className="f-w-500 m-b-5">Poster:</label>

                  <span>1080 x 1920</span>
                </div>

                <Poster
                  image={poster}
                  onChange={setPoster}
                  className="movie-poster-style"
                />
              </div>

              <div>
                <div className="poster-block">
                  <div className="image-label">
                    <label className="f-w-500 m-b-5">Image title:</label>

                    <span>950 × 250</span>
                  </div>
                  <Poster image={imageTitle} onChange={setImageTitle} />
                </div>
                <div className="poster-block" style={{ marginTop: 10 }}>
                  <div className="image-label">
                    <label className="f-w-500 m-b-5">Cover:</label>

                    <span>1920x1080</span>
                  </div>
                  <Poster image={posterTv} onChange={setPosterTv} />
                </div>
              </div>
            </div>

            <div className="image-title" style={{ marginTop: 20 }}>
              <div className="poster-block">
                <div className="image-label">
                  <label className="f-w-500 m-b-5">Tv Highlight:</label>

                  <span>1920x1080</span>
                </div>
                <Poster image={tvHighlight} onChange={setTvHighlight} />
              </div>
              <div className="poster-block">
                <div className="image-label">
                  <label className="f-w-500 m-b-5">Mobile Highlight:</label>

                  <span>1920x1080</span>
                </div>

                <Poster image={mobileHighlight} onChange={setMobileHighlight} />
              </div>
            </div>

            <div className="backdrop-block">
              <div className="image-label">
                <label className="f-w-500 m-b-5">Backdrop:</label>

                <span>1920x1080</span>
              </div>
              <Backdrop image={backdrop} onChange={setBackdrop} />
            </div>

            <Rating rating={rating} onChange={setRating} />

            <div style={{ marginTop: 20 }}>
              <label className="f-w-500 m-b-5">Content Monetization Type:</label>

              <div className="radio-button-style">
                <input
                  type="radio"
                  id="free"
                  name="free"
                  value="free"
                  onClick={selectContentMonetizationType}
                  checked={contentMonetizationTypeOption.free}
                  readOnly={true}
                />
                <label htmlFor="free">FREE</label>
              </div>

              <div className="radio-button-style">
                <input
                  type="radio"
                  id="svod"
                  name="svod"
                  value="svod"
                  onClick={selectContentMonetizationType}
                  checked={contentMonetizationTypeOption.svod}
                  readOnly={true}
                />
                <label htmlFor="svod">SVOD</label>
              </div>

              <div className="radio-button-style">
                <input
                  type="radio"
                  id="tvod"
                  name="tvod"
                  value="tvod"
                  onClick={selectContentMonetizationType}
                  checked={contentMonetizationTypeOption.tvod}
                  readOnly={true}
                />
                <label htmlFor="tvod">TVOD</label>
              </div>

              {contentMonetizationTypeOption.tvod && (
                <div className="section-style" style={{ marginTop: 20 }}>
                  <div className="block-style">
                    <SelectComponent
                      label="TVOD pricing"
                      value={pricing}
                      options={optionsOfPricing}
                      change={setPricing}
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                </div>
              )}

              {contentMonetizationTypeOption.svod && (
                <div style={{ marginTop: 10 }}>
                  <label htmlFor="">Tariffs</label>
                  <Select
                    options={svodOptions}
                    value={selectedSvod}
                    onChange={setSelectedSvod}
                    mode="multiple"
                    style={{ width: "100%", marginTop: 5 }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="block-style">
            <TextareaComponent
              style={{ height: 200 }}
              label="Description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter move description"
            />

            <div style={{ marginTop: 20 }}>
              {editableMovieId && <Translations id={id} model="movie" />}
            </div>
          </div>

          <div className="block-style">
            <div className="inputs-section">
              <div className="input-section-row">
                <DatePickerComponent
                  label="Release date"
                  selected={releaseDate}
                  onChange={setReleaseDate}
                />

                <div>
                  <label className="f-w-500 m-b-5">Age restriction:</label>
                  <InputNumber
                    value={pg}
                    onChange={setPg}
                    placeholder="Enter age..."
                    controls={false}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>

            {mediaType.value === "movie" && (
              <div className="into-section-row">
                <InputComponent
                  type="number"
                  name="duration"
                  label="Duration(m)"
                  onChange={onChangeSkeep}
                  value={duration}
                  placeholder="Enter duration..."
                />

                <InputComponent
                  min={0}
                  type="number"
                  name="intro_start_time"
                  label="Intro start time(s)"
                  value={introStartTime}
                  onChange={onChangeSkeep}
                  placeholder={"Enter start time"}
                />

                <InputComponent
                  min={0}
                  type="number"
                  name="intro_end_time"
                  label="Intro end time(s)"
                  value={introEndTime}
                  onChange={onChangeSkeep}
                  placeholder={"Enter end time"}
                />

                <InputComponent
                  min={0}
                  type="number"
                  name="credits_time"
                  label="Credits time(s)"
                  value={creditsTime}
                  onChange={onChangeSkeep}
                  placeholder={"Enter credits time"}
                />
              </div>
            )}

            <SelectComponent
              label="Trailer type"
              isMulti={false}
              value={trailerStreamType}
              options={streamTypeOptions}
              change={setTrailerStreamType}
            />

            {trailerStreamType?.value === "internal" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "95%", marginRight: 10 }}>
                  <TranscodingSearch
                    label="Trailer url"
                    value={selectedTranscodingIdForTrailer}
                    onChange={setSelectedTranscodingIdForTrailer}
                  />
                </div>

                <div
                  className="media-play-button"
                  style={{
                    marginTop: 12,
                    fontSize: 23,
                    color: selectedTranscodingForTrailer ? "#14746f" : "#8f8f8f",
                    cursor: selectedTranscodingForTrailer ? "pointer" : "no-drop",
                  }}
                  onClick={() => {
                    setVideoSrc(selectedTranscodingForTrailer?.stream_url);
                  }}
                >
                  <i className="fas fa-play-circle" />
                </div>
              </div>
            ) : (
              <UrlWithPlayer
                name="trailer_url"
                label="Trailer url"
                value={trailerUrl}
                placeholder="Enter url..."
                onChange={setTrailerUrl}
                openPlayer={() => {
                  setVideoSrc(trailerUrl);
                }}
              />
            )}

            {mediaType.value === "movie" && (
              <>
                <SelectComponent
                  label="Stream type"
                  isMulti={false}
                  value={streamType}
                  options={streamTypeOptions}
                  change={setStreamType}
                />
                {streamType?.value === "internal" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "95%", marginRight: 10 }}>
                      <TranscodingSearch
                        label="Stream url"
                        value={selectedTranscodingId}
                        onChange={setSelectedTranscodingId}
                      />
                    </div>

                    <div
                      className="media-play-button"
                      style={{
                        marginTop: 12,
                        fontSize: 23,
                        color: selectedTranscoding ? "#14746f" : "#8f8f8f",
                        cursor: selectedTranscoding ? "pointer" : "no-drop",
                      }}
                      onClick={() => {
                        setVideoSrc(selectedTranscoding?.stream_url);
                      }}
                    >
                      <i className="fas fa-play-circle" />
                    </div>
                  </div>
                ) : (
                  <UrlWithPlayer
                    name="stream_url"
                    label="Stream url"
                    value={streamUrl}
                    placeholder="Enter url..."
                    onChange={setStreamUrl}
                    openPlayer={() => {
                      setVideoSrc(streamUrl);
                    }}
                  />
                )}
              </>
            )}

            <SelectComponent
              label="Genre"
              isMulti={true}
              value={genres}
              options={optionsOfGenre}
              change={selectGenre}
              style={{ marginBottom: 10 }}
            />

            <SelectComponent
              label="Country"
              isMulti={true}
              value={countries}
              options={optionsOfCountry}
              change={setCountries}
            />
            {permissionMenu?.Content_Provider && (
              <SelectComponent
                isClearable={true}
                label="Provider"
                value={provider}
                options={providersOption}
                change={(e) => setSelectedProvider(e)}
              />
            )}

            <div style={{ display: "flex", gap: 20 }}>
              <label className="checkbox-label">
                Is protected
                <input
                  type="checkbox"
                  checked={isProtected}
                  onChange={onProtect}
                  name="is_protected"
                />
                <span className="checkmark" />
              </label>

              <label className="checkbox-label">
                Geolocation restriction
                <input
                  type="checkbox"
                  checked={isLocationRestriction}
                  onChange={(e) => setIsLocationRestriction(e.target.checked)}
                  name="is_location_restriction"
                />
                <span className="checkmark" />
              </label>
            </div>

            {isLocationRestriction && (
              <SelectComponent
                label="Available countries"
                isMulti={true}
                value={availableCountries}
                change={setAvailableCountries}
                options={optionsOfCountry}
              />
            )}
          </div>

          <div className="block-style">
            <DatePickerComponent
              isClearable
              label="License ending date"
              selected={licenseEndingDate}
              onChange={setlicenseEndingDate}
            />

            <InputComponent
              label="Original audio language"
              name="original_audio_language"
              value={originalAudioLanguage}
              onChange={(e) => setOriginalAudioLanguage(e.target.value)}
            />

            <label className="f-w-500 m-b-5">Notes:</label>

            <InputComponent
              style={{ width: "100%" }}
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />

            {id && <Notes id={id} />}
          </div>

          {editableMovieId && mediaType.value === "tv_show" && (
            <div className="section-style">
              <div className="block-style">
                <Seasons seasons={seasons} openPopup={openPopup} select={selectSeason} />
              </div>

              <div className="block-style">
                <Series
                  openPopup={openPopup}
                  selectedSeason={selectedSeason}
                  // getData={getMediaInfo}

                  getData={fetchData}
                  streamType={streamType}
                />
              </div>
            </div>
          )}
        </div>

        <div style={{ textAlign: "center" }}>
          {editableMovieId ? (
            <ButtonComponent notIcon title="Edit" type="edit" action={onClickAddOrEdit} />
          ) : (
            <ButtonComponent notIcon title="Add" type="add" action={onClickAddOrEdit} />
          )}
        </div>
      </div>

      {/* {visibleTheSeasonPopup && (
        <SeasonPopup
          movieId={id}
          getData={getMediaInfo}
          onClose={closeSeasonPopup}
          editableSeason={editableSeason}
        />
      )} */}
      <SeasonPopup
        movieId={id}
        // getData={getMediaInfo}
        // getData={fetchData}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={closeSeasonPopup}
        editableSeason={editableSeason}
        open={visibleTheSeasonPopup}
      />

      {deletableSeason && (
        <QuestionPopup
          type="Delete"
          action={deleteSeason}
          onClose={() => setDeletableSeason(null)}
          title={`Do you want to delete this season ?`}
        />
      )}

      {/* {visibleTheSeriePopup && (
        <SeriePopup
          seasonId={selectedSeason?.id}
          editableSerie={editableSerie}
          onClose={closeSeriePopup}
          getData={getMediaInfo}
        />
      )} */}

      <SeriePopup
        seasonId={selectedSeason?.id}
        editableSerie={editableSerie}
        onClose={closeSeriePopup}
        // getData={getMediaInfo}
        getData={() => setGetAgain((prev) => !prev)}
        open={visibleTheSeriePopup}
      />

      {deletableSerie && (
        <QuestionPopup
          type="Delete"
          action={deleteSerie}
          onClose={() => setDeletableSerie(null)}
          title={`Do you want to delete this season ?`}
        />
      )}
      {videoSrc && (
        <VideoComponent
          url={videoSrc}
          onClose={() => setVideoSrc(null)}
          showRang={mediaType.value === "movie"}
          saveSkeepOption={saveSkeepOption}
          introStartTime={introStartTime}
          introEndTime={introEndTime}
          creditsTime={creditsTime}
          originalAudioLanguage={originalAudioLanguage}
          useToken={streamType && streamType?.value === "internal" ? true : false}
        />
      )}
    </>
  );
}
