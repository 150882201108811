import React from 'react';

const titleStyle = {
    fontSize: 30,
    lineHeight: 3,
}

const subTitleStyle = {
    fontSize: 17,
    lineHeight: 3,
    color: '#a0a0a0',
    marginBottom: 20
}

const QuestionPopup = (props) => {
    return (
        <div className="question-popup-body">
            <p style={titleStyle}>{props.title}</p>

            <p style={subTitleStyle}>{props.subtitle}</p>
        </div>
    )
}

export default QuestionPopup;