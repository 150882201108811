import { notification } from "antd";
import { useState } from "react";

import REQUESTS from "../../api/requests";

import PageComponent from "../../_components/page/PageComponent";
import HistoryTable from "./HistoryTable";

export default function HistoryPage() {
    const [getAgain, setGetAgain] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.HISTORY.GET(query)
            .then((response) => {
                console.log(response.message);
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    const handleMenuClick = (event, item) => {
        switch (event.key) {
            case "delete":
                REQUESTS.HISTORY.DELETE({ id: item.id })
                    .then((response) => {
                        if (!response.error) {
                            notification.success({
                                message: "Success",
                                description: response.message,
                            });
                            setGetAgain((prev) => !prev);
                        }
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Error",
                            description: error,
                        });
                    });

                break;

            default:
                break;
        }
    };

    const clearAll = () => {
        REQUESTS.HISTORY.DELETE({ all: true })
            .then((response) => {
                console.log(response);
                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                notification.success({
                    message: "Success",
                    description: response.message,
                });

                setGetAgain((prev) => !prev);
            })

            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    return (
        <PageComponent routes={["History"]}>
            <HistoryTable
                clearAll={clearAll}
                getAgain={getAgain}
                getDataSource={getDataSource}
                handleMenuClick={handleMenuClick}
            />
        </PageComponent>
    );
}
