import styles from "./chart.module.scss";
import { Radio } from "antd";

export default function ChartComponent({ title, children, radioGroup, selectedTab }) {
  return (
    <div className={styles["chart-component"]}>
      <div className={styles["title"]}>{title}</div>
      {radioGroup && (
        <Radio.Group
          defaultValue={radioGroup[2]}
          buttonStyle="solid"
          style={{ marginBottom: 25 }}
          size="small"
        >
          {radioGroup?.map((item, ind) => {
            return (
              <Radio.Button key={item?.id} value={item} onClick={() => selectedTab(item)}>
                {item?.name}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      )}
      {children}
    </div>
  );
}
